import {useTranslation} from "react-i18next";
import BaseCard from "../Components/BaseCard";
import Navbar from "../Components/Navbar";
import ServiceCard from "../Components/ServiceCard";
import PageTitle from "../Components/PageTitle";
import {useEffect} from "react";

function DownloadServices() {
    const { t } = useTranslation();
    const data = [
        {id: 1, title: 'youtube', src: 'youtube', svg: "youtube"},
        {id: 2, title: 'radio_javan', src: 'radiojavan', svg: "radioJavan"},
        // {id: 2, title: 'instagram', src: 'instagram', svg: "instagram"},
        // {id: 3, title: 'sound_cloud', src: 'soundcloud', svg: "soundcloud"},
    ]
    const services = data.map(service => {
        return <ServiceCard key={service.id} title={t(`services.${service.title}`)} svgSrc={`/Images/Svg/${service.svg}.svg`} route={`downloader/${service.src}`} name={service.src}/>
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return  <section className={"download-services"}>
        <Navbar actions={[{action:"back", title: t("actions.back")}]}/>
        <div className={"download-services__title-part"}>
            <PageTitle title={t('select_platform')} />
        </div>
        <div className={'services services--downloaders'}>
            {services}
        </div>
    </section>
}

export default DownloadServices