import React, {useEffect, useState} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Homepage from "./Pages/Home";
import Profile from "./Pages/Profile";
import PhotoServices from "./Pages/PhotoServices";
import UploadImagePage from "./Pages/UploadImage";
import DownloadServices from "./Pages/DownloadServices";
import PasteLink from "./Pages/PasteLink";
import DownloadProcess from "./Pages/DownloadProcess";
import SaveImagePage from "./Pages/SaveImagePage";
import CompleteMessage from "./Pages/CompleteMessage";
import Support from "./Pages/Support";
import ErrorPage from "./Pages/ErrorPage";
import Test from "./Pages/Test";
import {ReactSVG} from "react-svg";
import Payment from "./Pages/Payment";
import Converters from "./Pages/Converters";
import GetConvert from "./Pages/GetConvert";
import Bill from "./Pages/Bill";
import {useHapticFeedback, useShowPopup} from "@vkruglikov/react-telegram-web-app"

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Homepage/>,
    },
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      path: "/photo",
      element: <PhotoServices />,
    },
    {
      path: "photo/:serviceName/upload",
      element: <UploadImagePage/>,
    },
    {
      path: "/downloader",
      element: <DownloadServices/>,
    },
    {
      path: "/downloader/:serviceName",
      element: <PasteLink/>,
    },
    {
      path: "/downloader/:serviceName/downloadprocess",
      element: <DownloadProcess/>,
    },
    {
      path: "/converter",
      element: <Converters/>,
    },
    {
      path: "/converter/:category/:serviceName",
      element: <GetConvert />,
    },
    {
      path: "/resultPreview",
      element: <SaveImagePage/>
    },
    {
      path: "/proccessComplete",
      element: <CompleteMessage/>
    },
    {
      path: "/support",
      element: <Support/>
    },
    {
      path: "/error",
      element: <ErrorPage/>
    },
    {
      path: "profile/payment",
      element: <Payment/>
    },
    {
      path: "profile/payment/bill",
      element: <Bill/>
    },
    {
      path: "test",
      element: <Test/>
    }
  ]);
  const showPopup = useShowPopup()
  const [impactOccurred, notificationOccurred, selectionChanged] = useHapticFeedback()
  const [isInvalidVersion, setIsInvalidVersion] = useState(false)

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      if (!window.Telegram.WebApp.isVersionAtLeast("6.9")) {
        notificationOccurred("error")
        if (window.Telegram.WebApp.isVersionAtLeast("6.2")) {
          showPopup({message: "Please update your Telegram app to the latest version to use this app."})
        } else {
          setIsInvalidVersion(true)
        }
      }
      // Alternatively to what can be set with react-telegram-web-app, you can directly set the following properties:
      try {
        window.Telegram.WebApp.requestWriteAccess()
      } catch (e) {
        console.log(e)
      }
    }
  }, []);

  return (
    <>
      <div className={"sky-background"}>
        <ReactSVG className={'stars-animation'} src={"/Images/Svg/SkyWhitStars.svg"}/>
      </div>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
