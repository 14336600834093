import React from "react";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Navbar from "../Components/Navbar";
import ServiceCard from "../Components/ServiceCard";
import {toolixGenerateToken, userInfo, getServices} from "../HttpClient/index";
import systemLanguageHandler from "../lang";
import Loader from "../Components/Loader";
import {GlobalStateContext} from "../Context/GlobalStateContext";
import DipositPreview from "../Components/DipositPreview";
// import {Modal, useToast} from '@chakra-ui/react'
import {BackButton, MainButton, useCloudStorage, useHapticFeedback, useInitData, useShowPopup} from "@vkruglikov/react-telegram-web-app"


function Home() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const {state, dispatch} = useContext(GlobalStateContext)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)
    const [dipositData, setDipositData] = useState([])
    const tempInitData = {
        "init_data": "query_id=AAHOI10TAAAAAM4jXRNPkSp0&user=%7B%22id%22%3A324871118%2C%22first_name%22%3A%22Masoud%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22msdshojaeiii%22%2C%22language_code%22%3A%22fa%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1719067701&hash=57dd2eca94097c7ee7c4d0cf76cb2933694d5311e094ec11380788df4aa24fe4"
    }


    useEffect(() => {
        let initData = (state.isProduction) ?  {"init_data": replaceEdgeQuotes(window.telegram.initData)} :  tempInitData

        console.clear()

        tokenHandler(initData)
        window.scrollTo(0, 0)
        // dispatch({type: "SET_TELEGRAM_DATA_UNSAFE", payload:window.telegram.initDataUnsafe})
        // dispatch({type: "SET_TELEGRAM_INIT_DATA", payload:window.telegram.initData})
    }, []);

    const tokenHandler = (initData) => {
        toolixGenerateToken(initData)
            .then(response => {
                if (response.data.access_token && response.data.token_type) {
                    window.localStorage.setItem('token', response.data.access_token)
                    window.localStorage.setItem('token_type', response.data.token_type)

                    fetchUserInfo()
                    fetchServices()
                }
            })
            .catch(err => {
                navigate("/error",{state: {errorData: {msg: t("errors.token_not_found"), obj: err.data, retryRout:"/"}}})
                setIsLoading(false)
            })
    }

    const fetchServices = () => {
        getServices()
            .then(services => {
                let finalServices = []
                if (services.data) {
                    finalServices = services.data
                    finalServices.unshift({id: "profile", name: "profile"})
                    finalServices.push({id: "support", name: "support"})
                }
                setData(finalServices)
            })
            .catch()
    }

    const fetchUserInfo = () => {
            userInfo()
                .then(res => {
                    if (res.data) {
                        dispatch({type: "SET_USER_INFO", payload: res.data})
                        systemLanguageHandler(res.data.pref_language)

                        if (res.data.has_pending_deposit) setDipositData(res.data.pending_deposits)
                    }
                })
                .catch(err => {
                    const errMessage = t("errors.user_info")

                    navigate("/error",{state: {errorData: {msg: errMessage, obj: err.data, retryRout:"/"}}})
                })
                .finally(() => setIsLoading(false))
    }

    const replaceEdgeQuotes = (inputString) => {
        // Use a regular expression to match single quotes at the start and end of the string
        return inputString.replace(/^'|'$/g, '"');
    }

    const themeHandler = (nextTheme) => {
        const bodyElement = document.querySelector("BODY")

        bodyElement.setAttribute('data-theme',"")
        bodyElement.setAttribute('data-theme',nextTheme)
        window.localStorage.setItem("data-theme", nextTheme)
    }

    const services = data && data.map(service => {
        return <ServiceCard
            key={service.id}
            title={t(`services.${service.name}`)}
            svgSrc={`/Images/Svg/${service.name}.svg`}
            route={service.name}
            name={service.name}
            callback={themeHandler}
        />
    })

    return (
        <section className={"homepage"}>
            {dipositData.length && <DipositPreview dipositData={dipositData}/>}

            {
                isLoading ?
                    <Loader title={t("pages.homepage.loading")}/>
                    :
                    <>
                        <Navbar actions={[{action:"close", title: t("actions.close")}]} />
                        <div className={"services"}>
                            {services}
                        </div>
                    </>
            }
        </section>
    )
}

export default Home