import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={70}
        height={55}
        viewBox="0 0 70 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_286_2696)">
            <g filter="url(#filter0_dif_286_2696)">
                <path
                    d="M63.2489 0.763672H17.056C14.1124 0.763672 11.7261 3.32871 11.7261 6.49284V43.1595C11.7261 46.3236 14.1124 48.8887 17.056 48.8887H63.2489C66.1926 48.8887 68.5789 46.3236 68.5789 43.1595V6.49284C68.5789 3.32871 66.1926 0.763672 63.2489 0.763672Z"
                    fill="url(#paint0_linear_286_2696)"
                />
            </g>
            <g filter="url(#filter1_di_286_2696)">
                <path
                    d="M52.9442 6.875H6.75134C3.80769 6.875 1.42139 9.44003 1.42139 12.6042V46.9792C1.42139 50.1433 3.80769 52.7083 6.75134 52.7083H52.9442C55.8879 52.7083 58.2742 50.1433 58.2742 46.9792V12.6042C58.2742 9.44003 55.8879 6.875 52.9442 6.875Z"
                    fill="url(#paint1_linear_286_2696)"
                />
                <path
                    d="M52.9443 7.06641H6.75141C3.90588 7.06641 1.59912 9.54594 1.59912 12.6046V46.9796C1.59912 50.0383 3.90588 52.5178 6.75141 52.5178H52.9443C55.7898 52.5178 58.0966 50.0383 58.0966 46.9796V12.6046C58.0966 9.54594 55.7898 7.06641 52.9443 7.06641Z"
                    stroke="url(#paint2_linear_286_2696)"
                />
            </g>
            <g filter="url(#filter2_df_286_2696)">
                <path
                    d="M33.4009 27.1183C36.1483 27.1183 38.3755 24.7242 38.3755 21.7711C38.3755 18.8179 36.1483 16.4238 33.4009 16.4238C30.6535 16.4238 28.4263 18.8179 28.4263 21.7711C28.4263 24.7242 30.6535 27.1183 33.4009 27.1183Z"
                    fill="url(#paint3_linear_286_2696)"
                />
            </g>
            <g filter="url(#filter3_i_286_2696)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M58.0448 48.6469C57.3804 50.9977 55.3483 52.7085 52.9441 52.7085H6.75119C5.2124 52.7085 3.82594 52.0076 2.85303 50.8866L16.2549 27.211C16.9583 25.9685 18.6441 25.9934 19.3153 27.2563L31.0703 49.373L43.6153 27.211C44.3188 25.9685 46.0045 25.9934 46.6757 27.2563L58.0448 48.6469Z"
                    fill="url(#paint4_linear_286_2696)"
                />
            </g>
            <g filter="url(#filter4_i_286_2696)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39.0776 35.2278L43.6156 27.211C44.3191 25.9685 46.0048 25.9934 46.676 27.2563L51.0899 35.5608C49.432 36.7425 47.4432 37.4307 45.3048 37.4307C42.9728 37.4307 40.8188 36.6122 39.0776 35.2278Z"
                    fill="white"
                />
            </g>
            <g filter="url(#filter5_i_286_2696)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.458 35.6854L16.2551 27.211C16.9585 25.9685 18.6443 25.9934 19.3155 27.2563L23.7734 35.6436C22.0398 37.0074 19.9018 37.8126 17.5889 37.8126C15.2998 37.8126 13.182 37.0239 11.458 35.6854Z"
                    fill="white"
                />
            </g>
        </g>
        <defs>
            <filter
                id="filter0_dif_286_2696"
                x={7.72607}
                y={-1.23633}
                width={64.853}
                height={58.125}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={2} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.298039 0 0 0 0 0.454902 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_286_2696"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_286_2696"
                    result="shape"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={8} />
                <feGaussianBlur stdDeviation={5} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
                />
                <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect2_innerShadow_286_2696"
                />
                <feGaussianBlur
                    stdDeviation={0.5}
                    result="effect3_foregroundBlur_286_2696"
                />
            </filter>
            <filter
                id="filter1_di_286_2696"
                x={-2.90088}
                y={4.56641}
                width={65.4976}
                height={54.4512}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={2} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.298039 0 0 0 0 0.454902 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_286_2696"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_286_2696"
                    result="shape"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={5} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.298039 0 0 0 0 0.454902 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect2_innerShadow_286_2696"
                />
            </filter>
            <filter
                id="filter2_df_286_2696"
                x={24.4263}
                y={14.4238}
                width={17.9492}
                height={18.6943}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={2} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.239216 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_286_2696"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_286_2696"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation={0.5}
                    result="effect2_foregroundBlur_286_2696"
                />
            </filter>
            <filter
                id="filter3_i_286_2696"
                x={2.85303}
                y={26.2939}
                width={55.1919}
                height={30.4141}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={5} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
                />
                <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_286_2696"
                />
            </filter>
            <filter
                id="filter4_i_286_2696"
                x={39.0776}
                y={26.2939}
                width={12.0122}
                height={15.1367}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={5} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.298039 0 0 0 0 0.454902 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_286_2696"
                />
            </filter>
            <filter
                id="filter5_i_286_2696"
                x={11.458}
                y={26.2939}
                width={12.3154}
                height={15.5186}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={5} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.298039 0 0 0 0 0.454902 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_286_2696"
                />
            </filter>
            <linearGradient
                id="paint0_linear_286_2696"
                x1={16.1677}
                y1={0.763676}
                x2={71.8245}
                y2={43.9993}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#D4B3FF" />
                <stop offset={1} stopColor="#70A9FF" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_286_2696"
                x1={5.86301}
                y1={6.875}
                x2={59.4527}
                y2={50.5863}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E4F9FF" stopOpacity={0.1} />
                <stop offset={1} stopColor="#E4F9FF" stopOpacity={0.8} />
            </linearGradient>
            <linearGradient
                id="paint2_linear_286_2696"
                x1={10.127}
                y1={14.1324}
                x2={48.5371}
                y2={46.6975}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E4F9FF" stopOpacity={0.1} />
                <stop offset={1} stopColor="#E4F9FF" stopOpacity={0.8} />
            </linearGradient>
            <linearGradient
                id="paint3_linear_286_2696"
                x1={31.4466}
                y1={16.9967}
                x2={36.618}
                y2={26.4338}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.145833} stopColor="#FFBE5A" />
                <stop offset={0.59375} stopColor="#FF861A" />
                <stop offset={1} stopColor="#FF600D" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_286_2696"
                x1={30.4489}
                y1={26.2939}
                x2={30.4489}
                y2={52.7085}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#70A9FF" stopOpacity={0.1} />
                <stop offset={1} stopColor="#70A9FF" stopOpacity={0.8} />
            </linearGradient>
            <clipPath id="clip0_286_2696">
                <rect width={70} height={55} fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export default SVGComponent;
