import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={42}
        height={42}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_356_4299)">
            <path
                d="M41.9999 32.1563C41.9999 32.6747 41.5799 33.0947 41.0615 33.0947H3.28118L9.88962 39.4406C10.2637 39.8016 10.2768 40.3922 9.91587 40.7728C9.55493 41.1469 8.9643 41.16 8.58368 40.7991L0.288679 32.8388C0.0130542 32.5763 -0.0788208 32.1628 0.0655542 31.8084C0.209929 31.4541 0.551179 31.2178 0.938367 31.2178H41.0549C41.5799 31.2178 41.9999 31.6378 41.9999 32.1563Z"
                fill="#34495C"
            />
            <path
                d="M9.65994 23.9072C9.65994 24.1369 9.57463 24.3731 9.404 24.5503L1.62744 32.806C1.27307 33.1866 0.675878 33.1997 0.295253 32.8453C-0.078809 32.491 -0.0984965 31.8938 0.255878 31.5131L8.039 23.2575C8.39338 22.8769 8.99057 22.8638 9.37119 23.2181C9.5615 23.4085 9.65994 23.6578 9.65994 23.9072Z"
                fill="#34495C"
            />
            <path
                d="M9.65994 23.9072C9.65994 24.1369 9.57463 24.3731 9.404 24.5503L1.62744 32.806C1.27307 33.1866 0.675878 33.1997 0.295253 32.8453C-0.078809 32.491 -0.0984965 31.8938 0.255878 31.5131L8.039 23.2575C8.39338 22.8769 8.99057 22.8638 9.37119 23.2181C9.5615 23.4085 9.65994 23.6578 9.65994 23.9072Z"
                fill="#34495C"
            />
            <path
                d="M9.65994 23.9072C9.65994 24.1369 9.57463 24.3731 9.404 24.5503L1.62744 32.806C1.27307 33.1866 0.675878 33.1997 0.295253 32.8453C-0.078809 32.491 -0.0984965 31.8938 0.255878 31.5131L8.039 23.2575C8.39338 22.8769 8.99057 22.8638 9.37119 23.2181C9.5615 23.4085 9.65994 23.6578 9.65994 23.9072Z"
                fill="#34495C"
            />
            <path
                d="M9.65994 23.9072C9.65994 24.1369 9.57463 24.3731 9.404 24.5503L1.62744 32.806C1.27307 33.1866 0.675878 33.1997 0.295253 32.8453C-0.078809 32.491 -0.0984965 31.8938 0.255878 31.5131L8.039 23.2575C8.39338 22.8769 8.99057 22.8638 9.37119 23.2181C9.5615 23.4085 9.65994 23.6578 9.65994 23.9072Z"
                fill="#34495C"
            />
            <path
                d="M9.65994 23.9072C9.65994 24.1369 9.57463 24.3731 9.404 24.5503L1.62744 32.806C1.27307 33.1866 0.675878 33.1997 0.295253 32.8453C-0.078809 32.491 -0.0984965 31.8938 0.255878 31.5131L8.039 23.2575C8.39338 22.8769 8.99057 22.8638 9.37119 23.2181C9.5615 23.4085 9.65994 23.6578 9.65994 23.9072Z"
                fill="#34495C"
            />
            <path
                d="M9.65994 23.9072C9.65994 24.1369 9.57463 24.3731 9.404 24.5503L1.62744 32.806C1.27307 33.1866 0.675878 33.1997 0.295253 32.8453C-0.078809 32.491 -0.0984965 31.8938 0.255878 31.5131L8.039 23.2575C8.39338 22.8769 8.99057 22.8638 9.37119 23.2181C9.5615 23.4085 9.65994 23.6578 9.65994 23.9072Z"
                fill="#34495C"
            />
            <path
                d="M42 9.84373C42 9.96185 41.9803 10.08 41.9344 10.1915C41.79 10.5459 41.4487 10.7822 41.0616 10.7822L0.938437 10.7822C0.42 10.7822 0 10.3622 0 9.84373C0 9.32529 0.42 8.90529 0.938437 8.90529L38.7188 8.90529L32.1103 2.56592C31.7362 2.20498 31.7231 1.61435 32.0841 1.23373C32.445 0.859666 33.0356 0.84654 33.4162 1.20748L41.7113 9.16123C41.9016 9.34498 42 9.58779 42 9.84373Z"
                fill="#FF6C22"
            />
            <path
                d="M42 9.84373C42 10.0734 41.9147 10.3097 41.7441 10.4869L33.9609 18.7425C33.6066 19.1231 33.0094 19.1362 32.6288 18.7819C32.2547 18.4275 32.235 17.8303 32.5894 17.4497L40.3725 9.19404C40.7269 8.81342 41.3241 8.8003 41.7047 9.15467C41.9016 9.33842 42 9.5878 42 9.84373Z"
                fill="#FF6C22"
            />
            <path
                d="M42 9.84373C42 10.0734 41.9147 10.3097 41.7441 10.4869L33.9609 18.7425C33.6066 19.1231 33.0094 19.1362 32.6288 18.7819C32.2547 18.4275 32.235 17.8303 32.5894 17.4497L40.3725 9.19404C40.7269 8.81342 41.3241 8.8003 41.7047 9.15467C41.9016 9.33842 42 9.5878 42 9.84373Z"
                fill="#FF6C22"
            />
            <path
                d="M42 9.84373C42 10.0734 41.9147 10.3097 41.7441 10.4869L33.9609 18.7425C33.6066 19.1231 33.0094 19.1362 32.6288 18.7819C32.2547 18.4275 32.235 17.8303 32.5894 17.4497L40.3725 9.19404C40.7269 8.81342 41.3241 8.8003 41.7047 9.15467C41.9016 9.33842 42 9.5878 42 9.84373Z"
                fill="#FF6C22"
            />
            <path
                d="M42 9.84373C42 10.0734 41.9147 10.3097 41.7441 10.4869L33.9609 18.7425C33.6066 19.1231 33.0094 19.1362 32.6288 18.7819C32.2547 18.4275 32.235 17.8303 32.5894 17.4497L40.3725 9.19404C40.7269 8.81342 41.3241 8.8003 41.7047 9.15467C41.9016 9.33842 42 9.5878 42 9.84373Z"
                fill="#FF6C22"
            />
            <path
                d="M42 9.84373C42 10.0734 41.9147 10.3097 41.7441 10.4869L33.9609 18.7425C33.6066 19.1231 33.0094 19.1362 32.6288 18.7819C32.2547 18.4275 32.235 17.8303 32.5894 17.4497L40.3725 9.19404C40.7269 8.81342 41.3241 8.8003 41.7047 9.15467C41.9016 9.33842 42 9.5878 42 9.84373Z"
                fill="#FF6C22"
            />
            <path
                d="M42 9.84373C42 10.0734 41.9147 10.3097 41.7441 10.4869L33.9609 18.7425C33.6066 19.1231 33.0094 19.1362 32.6288 18.7819C32.2547 18.4275 32.235 17.8303 32.5894 17.4497L40.3725 9.19404C40.7269 8.81342 41.3241 8.8003 41.7047 9.15467C41.9016 9.33842 42 9.5878 42 9.84373Z"
                fill="#FF6C22"
            />
        </g>
        <defs>
            <clipPath id="clip0_356_4299">
                <rect
                    width={42}
                    height={42}
                    fill="white"
                    transform="matrix(0 -1 1 0 0 42)"
                />
            </clipPath>
        </defs>
    </svg>
);
export default SVGComponent;
