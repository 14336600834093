import BaseButtonSm from "../Components/BaseButtonSm";
import {useEffect, useState} from "react";


function Navbar({actions, navItems, children}) {
    const actionList = actions && actions.map((action, index) => <BaseButtonSm key={action.title + index} action={action.action}>{action.title}</BaseButtonSm>)
    const [weHaveMessage, setWeHaveMessage] = useState(false)

    useEffect(() => {
        if (children) boardVisibilityHandler()
    },[])

    const boardVisibilityHandler = () => {
        setTimeout(() => setWeHaveMessage(true), 1500)
        // setTimeout(() => setWeHaveMessage(false), 10000)
    }

    const closeTheBoard = () => setWeHaveMessage(false)

    return <section className={"navbar"}>
        {navItems && <div className={"navbar__items"}>{navItems}</div>}
        {
            actionList &&
                <div className="actions">
                    {actionList}
                </div>
        }
        {
            children &&
            <div className={`navbar-board ${weHaveMessage && "show"}`}>
                {children}
                <div className={'navbar-close'} onClick={closeTheBoard}>close</div>
            </div>
        }
    </section>
}

export default Navbar