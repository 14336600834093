import Navbar from "../Components/Navbar";
import {useTranslation} from "react-i18next";
import PageTitle from "../Components/PageTitle";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {convertersCategory, converterItems} from "../convertersEngine";
import ConverterList from "../Components/ConverterList";
import ConverterItem from "../Components/ConverterIteml";

function Converters() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const renderObject = convertersCategory.map((category,index) => {
        return (
            <ConverterList key={`${category}-${index}`} title={t(`pages.converters.${category}`)}>
                {converterItems[category].map(converterItem => <ConverterItem key={converterItem.id} data={converterItem} />)}
            </ConverterList>
        )
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <section className={"converters"}>
            <Navbar actions={[{action:"back", title: t("actions.back")}]}/>
            <div className={"converters__title-section"}>
                <PageTitle title={t("converters")} />
            </div>
            <div className={"converters__content"}>
                {renderObject}
            </div>
        </section>
    )
}

export default Converters