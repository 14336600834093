import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {GlobalStateContext} from "../Context/GlobalStateContext";
import jalaali from "jalaali-js";
import {uploadRecipient, cancelPurchase} from "../HttpClient";
import {BackButton, MainButton, useCloudStorage, useHapticFeedback, useInitData, useShowPopup} from "@vkruglikov/react-telegram-web-app"
import {Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast} from "@chakra-ui/react";




export function DipositPreview({dipositData}) {
    const { t } = useTranslation();
    const toast = useToast()
    const [proofType, setProofType] = useState('photo');
    const [imageSrc, setImageSrc] = useState('');
    const [imageBase64, setImageBase64] = useState('');
    const [imageFile, setImageFile] = useState("")
    const [isOpenKeyboard, setIsOpenKeyboard] = useState(false);
    const [receipteString, setReceipteString] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    const {state, dispatch} = useContext(GlobalStateContext)

    useEffect(() => {
        onOpen()
    }, []);

    function convertToJalali(gDate) {
        const date = new Date(gDate);
        const gy = date.getUTCFullYear();
        const gm = date.getUTCMonth() + 1; // Month is 0-based
        const gd = date.getUTCDate();

        const jalaliDate = jalaali.toJalaali(gy, gm, gd);
        return `${jalaliDate.jy}/${jalaliDate.jm < 10 ? '0' + jalaliDate.jm : jalaliDate.jm}/${jalaliDate.jd < 10 ? '0' + jalaliDate.jd : jalaliDate.jd}`;
    }

    const proofTypeSetter = (type) => setProofType(type)

    const billUploadHandler = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            setImageFile(file)

            reader.onload = (event) => {
                setImageSrc(event.target.result);
                setImageBase64(event.target.result.split(',')[1])
            }

            reader.readAsDataURL(file);
        }
    }

    const textareaFoucsHandler = () => setIsOpenKeyboard(true)

    const textareaBlurHandler = () => setIsOpenKeyboard(false)

    const textareaChangesHandler = (event) => setReceipteString(event.target.value)

    const submitHandler = () => {
        const payload = {
            transaction_id: dipositData[0].id,
            user_id: state.userInfo.id,
            paid_amount: dipositData[0].paid_amount,
            received_credit: dipositData[0].received_credit,
            proof: proofType === "text" ? receipteString : null,
            file: proofType === "photo" ? imageFile : null,
        }

        uploadRecipient(payload)
            .then(res => {
                toast({
                    title: t("success"),
                    description: t("pages.bill.bill_upload"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
            })
            .catch(err => {
                toast({
                    title: t("errors.error"),
                    description: t("errors.bill_upload_receipt"),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            })
            .finally(() => onClose())
    }

    const rejectHandler = () => {
        cancelPurchase(dipositData[0].id)
            .then(response => {
                toast({
                    title: t("actions.cancel"),
                    description: t("pages.bill.bill_canceled"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
            })
            .catch(error => {
                toast({
                    title: t("errors.error"),
                    description: t("errors.bill_canceled"),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            })
            .finally(() => onClose())
    }

    return(
        <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton color='black' _hover={{color: "red"}} />
                <ModalHeader color='black'>{t("bill")}</ModalHeader>
                <ModalBody pb={6} color='black'>
                    <ul className={"inner"}>
                        <li className={"bill-item bill-item--modal bill-item--total-cash"}>
                            <span className={"bill-item__key"}>{t("pages.bill.total_payment")}:</span>
                            <span className={"bill-item__value"}>
                                {new Intl.NumberFormat("en").format(dipositData[0].paid_amount)}  {window.localStorage.getItem("lang") === "en" ? state.is_local ? "Toman" : "Dollar" : state.is_local ? "تومان" : "دلار"}
                            </span>
                        </li>
                        <li className={"bill-item bill-item--modal"}>
                            <span className={"bill-item__key"}>{t("pages.bill.token_number")}:</span>
                            <span className={"bill-item__value"}>{new Intl.NumberFormat("en").format(dipositData[0].received_credit)}</span>
                        </li>
                        <li className={"bill-item bill-item--modal"}>
                            <span className={"bill-item__key"}>{t("pages.bill.trans_id")}:</span>
                            <span className={"bill-item__value"}>{dipositData[0].id}</span>
                        </li>
                        <li className={"bill-item bill-item--modal"}>
                            <span className={"bill-item__key"}>{t("pages.bill.date")}:</span>
                            <span className={"bill-item__value"}>{convertToJalali(dipositData[0].created_at)}</span>
                        </li>
                        <li className={"bill-item bill-item--modal bill-item--upload-bill"}>
                            <span className={"bill-item__key"}>{t("pages.bill.upload")}</span>
                            <div className={"upload-bill"}>
                                <Tabs variant='soft-rounded' colorScheme='green' isFitted className={"upload-tab"}>
                                    <TabList className={"upload-tab-tablist"}>
                                        <Tab onClick={() => proofTypeSetter("photo")}>{t("pages.bill.photo_receipte")}</Tab>
                                        <Tab onClick={() => proofTypeSetter("text")}>{t("pages.bill.string_receipte")}</Tab>
                                    </TabList>
                                    <TabPanels className={"upload-tab-body"}>
                                        <TabPanel>
                                            <div className={"file-uploader"}>
                                                {
                                                    imageSrc ?
                                                        <div className={"receipte-image"}>
                                                            <img src={imageSrc} alt=""/>
                                                        </div>
                                                        :
                                                        <>
                                                            <label htmlFor="uploadBill" className={"upload-bill__upload-element"}>+</label>
                                                            <input type="file" id={"uploadBill"} onChange={billUploadHandler} style={{display: "none"}}/>
                                                        </>
                                                }
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className={"receipte-string"}>
                                                <textarea onFocus={textareaFoucsHandler} onBlur={textareaBlurHandler} onChange={textareaChangesHandler} className={"upload-bill__string-type"} placeholder={t("text_area_placeholder")}></textarea>
                                            </div>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                                {/*{*/}
                                {/*    (receipteString || imageSrc) &&*/}
                                {/*    <MainButton*/}
                                {/*        color="#62dbff"*/}
                                {/*        textColor="#ffffff"*/}
                                {/*        onClick={submitHandler}*/}
                                {/*        text={t("actions.submit")}*/}
                                {/*    />*/}
                                {/*}*/}
                            </div>
                        </li>
                    </ul>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={rejectHandler}>{t("actions.cancel")}</Button>
                    <Button colorScheme="blue" mr={3} onClick={submitHandler}>{t("actions.submit")}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default DipositPreview