import { ReactSVG } from 'react-svg'
import BasePhotoComparator from "./BasePhotoComparator";
import UploadIcon from "../SvgComponents/Upload";



function BaseImageWrapper({format, currentFileName, afterFileName}) {
    let currentFileAddress
    let afterFileAddress
    const comparativeMode = !!afterFileName
    const themeSuffix = document.querySelector('BODY').getAttribute("data-theme")

    if (format==="svg") currentFileAddress = "/Images/Svg/" + currentFileName + themeSuffix + '.svg'
    else if (format==="png" || format==="jpg") {
        currentFileAddress = "/Images/" + currentFileName + "." + format

        if (afterFileName) afterFileAddress = "/Images/" + afterFileName + "." + format
    }

    return (
        <div className={'image-wrapper'}>
            {
                format === "svg" ?
                // <ReactSVG className={'image-wrapper__svg'} src={currentFileAddress}/>
                <UploadIcon/>
                :
                    comparativeMode ?
                        <BasePhotoComparator currentFileAddress={currentFileAddress} afterFileAddress={afterFileAddress}/>
                        :
                        <img className={'image-wrapper__img'} title={currentFileName} src={currentFileAddress}/>
            }
        </div>
    )
}

export default BaseImageWrapper