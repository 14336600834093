import {useEffect, useRef, useState} from "react";
import { ImgComparisonSlider } from '@img-comparison-slider/react';

function BasePhotoComparator({imagePrefix, currentFileAddress, afterFileAddress}) {
    const beforeImageSRC = "Images" + "/" + imagePrefix + 'before.png'
    const afterImageSRC = "Images" + "/" + imagePrefix + 'after.png'
    const [inputValue, setInputValue] = useState("50")
    const imgRef = useRef()
    const [cardImageWidth, setCardImageWidth] = useState('')

    useEffect(() => {
        setCardImageWidth(imgRef.current.offsetWidth)
        window.addEventListener('resize', listenerHandler);

        return () =>  window.removeEventListener("resize",listenerHandler)
    }, [])


    function listenerHandler() {
        if (imgRef.current) setCardImageWidth(imgRef.current.offsetWidth)
    }

    return(
       <div className={"base-photo-comparator"} ref={imgRef}>
           <ImgComparisonSlider className={"image-comparison-slider"}>
               <img slot="first" className={"comparison-images"} src={currentFileAddress} />
               <img slot="second" className={"comparison-images"} src={afterFileAddress} />
           </ImgComparisonSlider>
       </div>
    )
}

export default BasePhotoComparator