import {useTranslation} from "react-i18next";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle";
import {useContext, useEffect, useState} from "react";
import CTAButton from "../Components/CTAButton";
import CoinIcon from "../SvgComponents/Coin";
import {useLocation, useNavigate} from "react-router-dom";
import {getPurchases, purchaseInitPay} from "../HttpClient/index";
import Loader from "../Components/Loader";
import {GlobalStateContext} from "../Context/GlobalStateContext";


function Payment() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {userData} = useLocation().state
    const [isLoading, setIsLoading] = useState(true)
    const [selectedPlanData, setSelectedPlanData] = useState(3000)
    const [credits, setCreadits]= useState(null)
    const [activePlan, setActivePlan] = useState(null)
    const {state, dispatch} = useContext(GlobalStateContext)

    useEffect(() => {
        window.scrollTo(0, 0)
        fetPurchases()
    }, []);

    const fetPurchases = () => {
        getPurchases()
            .then(res => setCreadits(res.data))
            .catch(err => console.log("error from get purchases",err))
            .finally(() => setIsLoading(false))
    }

    const tokenSelectorHandler = (data) => {
        const paymentValue = new Intl.NumberFormat("ar-EG").format(data.price)
        setActivePlan(data.name)
        setSelectedPlanData(data)
    }

    const initPay = () => {
        const payload = { "user_id": state.userInfo.id, "paid_amount": parseFloat(selectedPlanData.price), "received_credit": parseFloat(selectedPlanData.quantity)}
        // console.log("init_pay_payload: ", payload)
        setIsLoading(true)

        purchaseInitPay(payload)
            .then(InitPayResponse => {
                // console.log("InitPayResponse", InitPayResponse.data)
                paymentMethodHandler(InitPayResponse.data)
            })
            .catch(err => {
                // console.log("InitPayError", err)
                // console.log("errmessage: ", t("errors.init_pay"))
            })
            .finally(() => setIsLoading(false))
    }

    const paymentMethodHandler = (paymentData) => {
        window.Telegram.WebApp.showPopup({
            title: t("chose_payment_method"),
            message: t("chose_payment_method_description"),
            buttons: [
                { id: 'card', type: 'default', text: t("pages.payment.payment_card_to_card") },
                // { id: 'crypto', type: 'default', text: t("pages.payment.payment_cripto") },
                { id: 'cancel', type: 'destructive', text: t("actions.cancel") }
            ]
        }, (buttonId) => {
            if (buttonId !== 'cancel') {
                navigate("bill", {state: {paymentMethod: buttonId, planData:selectedPlanData, userIsLocate: userData, paymentData}})
            }
        })
    }

    const tokenListRendering = credits && credits.map(credit => (
        <li className={`token-list__item ${activePlan === credit.name && "active"}`} onClick={() => tokenSelectorHandler(credit)} key={`${credit.price + credit.name}`}>
            <span className={"token-list__icon"}>
                <CoinIcon className={"my-profile__mini-box-icon"}/>
                <span className={"token-list__amount"} >{new Intl.NumberFormat("en").format(credit.quantity)}</span>
            </span>
            {
                window.localStorage.getItem("lang") === "en" ?
                    <div className={"token-list__price"}>{new Intl.NumberFormat("en").format(credit.price)} {userData  ? "Toman" : "Dollar"} </div>
                    :
                    <div className={"token-list__price"}>{new Intl.NumberFormat("ar-EG").format(credit.price)} {userData ? "تومان" : "دلار"} </div>
            }

        </li>
    ))

    return (
        isLoading ?
            <Loader />
            :
            <div className={"payment"}>
                <Navbar actions={[{action:"back", title: t("actions.back")}]} />
                <div className={"payment__title-part"}>
                    <PageTitle title={t("select_token")} />
                </div>
                <div className={"payment__content"}>
                    <ul className={"token-list"}>
                        {tokenListRendering}
                    </ul>
                    {
                        activePlan &&
                        <div className={"payment__action-part"}>
                            <CTAButton title={t("actions.continue")} calback={initPay}/>
                        </div>
                    }
                </div>
            </div>
    )
}

export default Payment