import BasePhotoComparator from "./BasePhotoComparator";

function TheCardImage({imageName}) {
    // cartoonize enhancer bg_remover
    let currentFileAddress = "/Images/Webp/"  + imageName + '-before.webp'
    let afterFileAddress = "/Images/Webp/"  + imageName + '-after.webp'

    return (
        <div className={"card-image"}>
            <BasePhotoComparator currentFileAddress={currentFileAddress} afterFileAddress={afterFileAddress}/>
        </div>
    )
}

export default TheCardImage