import Navbar from "../Components/Navbar";
import {useTranslation} from "react-i18next";
import PageTitle from "../Components/PageTitle";
import CTAButton from "../Components/CTAButton";
import {useEffect} from "react";

function Support() {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={'support'}>
            <Navbar actions={[{action:"back", title: t("actions.back")}]}/>
            <div className={"support__title-section"}>
                <PageTitle title={t("support")} />
            </div>
            <div className={"support__content"}>
                <div className={"form-group"}>
                    <textarea className={"support__msg"} placeholder={t("text_area_placeholder")} name="" id="" cols="30" rows="10"></textarea>
                </div>
            </div>
            <div className={"support__action-part"}>
                <CTAButton title={t("actions.send")}/>
            </div>
        </div>
    )
}

export default Support