import Navbar from "../Components/Navbar";
import BaseImageWrapper from "../Components/BaseImageWrapper";
import CTAButton from "../Components/CTAButton";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import DoneVectorCrimson from "../SvgComponents/crimson/DoneVector";
import DoneVectorGreen from "../SvgComponents/green/DoneVector";
import DoneVectorOrange from "../SvgComponents/orange/DoneVector";
import DoneVectorLilac from "../SvgComponents/lilac/DoneVector";

function CompleteMessage() {
    const navigate = useNavigate()
    const [caption, setCaption] = useState("")
    const location = useLocation()
    const {t} = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
        location.state?.caption && setCaption(location.state.caption)
    }, []);

    const clickHandler = () =>   navigate("/")
    const vectorSpecifier = () => {
        const currentTheme = window.localStorage.getItem("data-theme");

        return currentTheme === "crimson" ? <DoneVectorCrimson /> : currentTheme === "green" ? <DoneVectorGreen/>
            : currentTheme === "orange" ? <DoneVectorOrange/> : <DoneVectorLilac />
    }

    return (
        <section className={"complete-message"}>
            <Navbar actions={[{action:"close", title: t("actions.close")}]}/>
            <div className={"complete-message__vector"}>
                {vectorSpecifier()}
                {caption && <div className={"error-page__message"}>{caption}</div>}
            </div>
            <div className={"complete-message__action-part"}>
                <CTAButton title={t("go_to_home")} calback={clickHandler}/>
            </div>
        </section>
    )
}

export default CompleteMessage