import {useNavigate} from "react-router-dom";
import ProfileIcon from "../SvgComponents/Profile";
import PhotoEditorsIcon from "../SvgComponents/PhotoEditors";
import DownloadersIcon from "../SvgComponents/Downloaders";
import ConvertersIcon from "../SvgComponents/Converters";
import SupportIcon from "../SvgComponents/Support";

const themeList = {
    photoServices: "lilac",
    downloaders: "green",
    converters: "orange",
    support: "crimson",
    profile: "cyan"
}

function ServiceCard({title, svgSrc, route, name, callback}) {
    const navigate = useNavigate()

    const iconSpecifier = () => {
        return name === "profile" ? <ProfileIcon className={"service-card__icon"}/> : name === "photo" ? <PhotoEditorsIcon className={"service-card__icon"}/>
            : name === "downloader" ? <DownloadersIcon className={"service-card__icon"}/> : name === "converter" ? <ConvertersIcon className={"service-card__icon"}/>
                :name === "support" ? <SupportIcon className={"service-card__icon"}/> : name === "radiojavan" ? <img src={`/Images/Webp/${name}Logo.webp`} className={"service-card__icon"} width={60} height={50}/>
                    : name === "instagram" ? <img src={`/Images/Webp/${name}Logo.webp`} className={"service-card__icon"} width={56} height={50}/>
                        : name === "soundcloud" ? <img src={`/Images/Webp/${name}Logo.webp`} className={"service-card__icon"} width={60} height={50}/>
                            : <img src="/Images/Webp/youtubeLogo.webp" className={"service-card__icon"} width={60} height={50}/>
    }

    const themeHandler = () => {
        if (!callback) return

        const currentTheme = themeList[`${route}`] || "app-default"
        callback(currentTheme)
    }

    const clickHandler = () => {
        themeHandler()

        if (route === "support") {
            window.location.href = 'https://t.me/ToolixSupport'

            return
        }

        navigate(`/${route}`)
    }

    return (
        <div className={`service-card service-card--${name}`} onClick={clickHandler}>
            {iconSpecifier()}
            <h4 className={"service-card__title"}>{title}</h4>
        </div>
    )
}

export default ServiceCard