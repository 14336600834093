import Navbar from "../Components/Navbar";
import {useTranslation} from "react-i18next";
import PageTitle from "../Components/PageTitle";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

function DownloadProcess() {
    const { t } = useTranslation();
    const {url} = useLocation().state
    const [downloadPercentage, setDownloadPercentage] = useState(69)
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)

        // here we should start the download, using url to download file
        // and start to update downloadPercentage

        setTimeout(() => {
            navigate("proccessComplete")
        },2000)
    }, [])

    return(
        <div className={"download-process"}>
            <Navbar actions={[{action:"close", title: t("actions.close")}]}/>
            <div className={"download-process__title-section"}>
                <PageTitle title={t("download_process")} />
            </div>
            <div className={"download-process__content"}>
                <div className="download-percentage">
                    {downloadPercentage}%
                </div>
            </div>
        </div>
    )
}

export default DownloadProcess