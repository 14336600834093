function Loader({title}) {
    return <div className={"loader-wrapper"} style={{display: "flex", flexDirection: "column"}}>
        <span className="loader"></span>
        {
            title &&
            <div style={{marginTop: "24px", textAlign: "justify", margin: "16px"}}>{title}</div>
        }
    </div>
}

export default Loader