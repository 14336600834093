export default {
    "services" : {
        "profile": "My profile",
        "cartoonize": "Cartoonize The Photo",
        "enhancer": "Photo Enhacer",
        "oldphoto_recovery": "Old Photo Recovery",
        "bg_remover": "Remove The Background",
        "photo": "Photo editors",
        "downloader": "Downloaders",
        "converter": "Convertors",
        "support": "Support",
        "radio_javan": "RadioJavan",
        "instagram": "Instagram",
        "sound_cloud": "SoundCloud",
        "youtube": "YouTube",
    },
    "actions": {
        "back": "Back",
        "save": "Save",
        "close": "Close",
        "retry": "Retry",
        "continue": "Continue",
        "message_to_support": "Message To Support",
        "paste": "Paste",
        "download": "Download",
        "send": "Send",
        "select_quality": "Select quality",
        "referral_link": "Referral link",
        "increase_credit": "Increase credit",
        "goto_link": "Go to link",
        "submit" : "Submit",
        "cancel" : "Cancel",

    },
    "errors": {
        "error": "Error!",
        "not_enough_credit": "Your credit is insufficient to use this service",
        "url": "Please enter valid url !",
        "wrong": "Something went wrong",
        "user_info": "There was an error getting user information",
        "token_not_found": "Authorization token not found!",
        "update_avatar": "Error updating avatar! Please try again",
        "update_language": "Error updating language! Please try again",
        "update_profile": "Error updating user information! Please try again",
        "prepare_download_link" : "There is a problem in preparing the download link!",
        "youtube_info": "There is a problem in receiving information from this link!",
        "sub_services": "Error in receiving service list!",
        "photo_services_server": "There was a problem communicating with the photo server!",
        "init_pay": "There was a problem getting the bill details. Please try again and if the problem persists, send a message to support and share the problem.",
        "bill_canceled" : "There was an error canceling the bill.",
        "vanceai_upload_error": "There is a problem uploading the photo. Please make sure that the format of your photo is JPG or PNG and the size of the photo is less than 10MB, then try again",
        "vanecai_process_step1": "There was a problem in the first stage of processing. Please try again. The token has not been deducted from your account",
        "vanecai_process_step2": "There was a problem in the second stage of processing. Please try again. The token has not been deducted from your account",
        "vanecai_download": "There is a problem downloading the file. Please check your photo gallery and downloaded files first. If you do not find the file you want, send the following code to support so that your problem can be checked.",
    },
    "pages": {
        "profile": {
            "token": "Token",
            "referrals": "Copy referral link",
            "referrals_count": "Referral count",
            "download_count": "Download count",
            "registry_date": "Registry date",
            "local": "iran",
            "non_local": "international",
        },
        "payment": {
            "currency_toman": "Toman",
            "payment_method": "Payment method",
            "payment_zaring": "Zarinpal",
            "payment_paypal": "Paypal",
            "payment_cripto": "Cripto",
            "payment_card_to_card": "Card to card",
        },
        "upload_image": {
            "loading": "Please wait, your photo is being processed, This process may take some time, please do not close the program",
            "format_guide": "The supported file formats are JPG and PNG.",
            "size_format": "The file size can not be larger than 10MB and the max resolution is 34 Megapixels",
            "require_token": "Number of coin for this service:",
            "token_inventory": "Your number of coins:",
            "prepare_download_link": "Preparing the download link",
        },
        "bill": {
            "total_payment": "Total payment",
            "token_number": "Token number",
            "trans_id": "Transaction ID",
            "date": "Date",
            "desc": "Description",
            "desc_desc": "Please deposit the amount of {{price}} {{currency}} to the card number below, then upload your receipt photo in the bottom section",
            "card_number": "Cart number",
            "card_number_copy": "Copy cart number",
            "name": "Name",
            "upload": "Upload your receipt using one of the methods below",
            "string_receipte": "text",
            "photo_receipte": "photo",
            "bill_canceled" : "The bill was cancelled.",
            "bill_upload": "After checking the bill, your account will be charged in the shortest time",

        },
        "homepage": {
            "loading": "loading"
        },
        "converters": {
            "type":"Type here",
            "weight": "Weight",
            "temperature": "Temperature",
            "kilogram": "Kilogram",
            "pounds": "Pounds",
            "kilogram_to_pounds": "Kilogram to pounds",
            "pounds_to_kilogram": "Pounds to kilogram",
            "celsius_to_fahrenheit": "Celsius to fahrenheit",
            "fahrenheit_to_celsius": "Fahrenheit to celsius",
        },
    },
    "success": "Success",
    "username" : "Username",
    "upload_image": "Upload Your Image",
    "bill": "Your bill",
    "preview": "Preview",
    "converters": "Converters",
    "go_to_home": "Go To Home",
    "select_platform": "Select the platfrom",
    "paste_link": "Paste your link",
    "download_process": "Download process",
    "support": "Message to support",
    "select_token": "Select the number of token",
    "text_area_placeholder": "Write your message ...",
    "copied": "Copied",
    "select_language": "Select your language",
    "url_placeholder": "Paste your {{serviceName}} URL.",
    "chose_location": "Chose location",
    "chose_location_description": "Specify your location range",
    "chose_payment_method": "Payment method",
    "chose_payment_method_description": "Choose your payment method",
    "coin_needed": "Coins neede",

}