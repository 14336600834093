import { ReactSVG } from 'react-svg'
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {GlobalStateContext} from "../Context/GlobalStateContext";
import {useContext} from "react";





function TheCardLink({linkTitle, action, id, servicePrice}) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const {state, dispatch} = useContext(GlobalStateContext)

    const getNavigate = () => {
        const payload = {id, serviceName: linkTitle, servicePrice}

        dispatch({type: "SET_SERVICE_INFO", payload})
        navigate(`${action}/upload`,{state: {serviceId: id, servicePrice}})
    }

    return (
        <div onClick={getNavigate} className={"card-link"}>
            <span className={"card-link__title"}>{t(`services.${linkTitle}`)}</span>
            <ReactSVG className={"card-link__link"} src={"/Images/Svg/ChevronRight.svg"}/>
        </div>
    )
}

export default TheCardLink