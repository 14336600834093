import axios from "axios";

const toolixBaseUrl = "https://api.toolix.app/api"

// Toolix API methods start
export async function toolixGenerateToken(data) {
    return await axios.post(`${toolixBaseUrl}/token`,data)
}

export async function youtubeInfoDownloader(videoUrl) {
    const url = `${toolixBaseUrl}/youtube/?url=${videoUrl}`
    const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token")
    const options = {
        method: 'get',
        url,
        maxBodyLength: Infinity,
        headers: {
            "Authorization": token
        }
    }

    return await axios.request(options)
}

export async function youtubeDownloader(videoId, itag) {
    const url = `${toolixBaseUrl}/youtube/link/generate/?video_id=${videoId}&itag=${itag}`;
    const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token");

    const options = {
        method: 'GET',
        url,
        headers: {
            "Authorization": token
        }
    }

    return await axios.request(options)
}

export async function radiojavanDownloader(audioUrl) {
    const url = `${toolixBaseUrl}/radiojavan/?url=${audioUrl}`;
    const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token");

    const options = {
        method: 'GET',
        url,
        headers: {
            "Authorization": token
        }
    }

    return await axios.request(options)
}

export async function soundcloudDownloader(url) {
    const options = {
        method: 'GET',
        url: 'https://soundcloud-scraper.p.rapidapi.com/v1/track/metadata',
        params: {
            track: url
        },
        headers: {
            'X-RapidAPI-Key': '71131ae007mshe6049b62a9c4a5ep136775jsn9091e36d3663',
            'X-RapidAPI-Host': 'soundcloud-scraper.p.rapidapi.com'
        }
    };

    return axios.request(options)
}

export async function instagramDownloader(url) {
    const options = {
        method: 'GET',
        url,
        headers: {
            'X-RapidAPI-Key': '71131ae007mshe6049b62a9c4a5ep136775jsn9091e36d3663',
            'X-RapidAPI-Host': 'instagram120.p.rapidapi.com'
        }
    };

    return await axios.request(options);
}

export async function userInfo() {
    const url = `${toolixBaseUrl}/users/info/`
    // const tokenStatic = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjaGF0X2lkIjozMjQ4NzExMTgsImV4cCI6MTcxMzYzODM3NH0.0TRWr0lg5n_nBf0Q0gH_3O7AXprBWhCrmtF3iNiDJvU"
    const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token")
    const options = {
        method: 'get',
        url,
        maxBodyLength: Infinity,
        headers: {
            'Authorization': token
        }
    }

    return await axios.request(options)
}

export const updateProfile = async (payload) => {
    const url = `${toolixBaseUrl}/users/update-profile`
    const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token")

    return await axios.put(url, payload, {
        headers: {
            'Authorization': token
        }
    })
}

export const serverSideImgHandling = async (imgBase64) => {
    const url = `${toolixBaseUrl}/cutout/upload-base64-image`
    const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token")
    const formData = new FormData()
    const config = {
        headers: {
            'Authorization': token
        }
    }

    formData.append("base64", imgBase64)
    const requestBody = {
        "base64": `${imgBase64}`
    }

    return axios.post(url, requestBody , config)

    // const url = 'https://api.toolix.app/api/vanceai/upload-photo'
    //
    // const formData = new FormData();
    // // const userId = window.tele.initDataUnsafe.user.id
    // const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token")
    //
    // formData.append('output_img_blob', outputImgBlob);
    // // formData.append('user_id', Number(userId));
    // console.log('test is pass', outputImgBlob)
    //
    // return axios.post(url, formData, {
    //     headers: {
    //         'Authorization': token
    //     }
    // })
}

export const getServices = async () => {
    const url = `${toolixBaseUrl}/services`;
    const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token");
    const options = {
        method: 'get',
        url,
        headers: {
            'Authorization': token
        }
    };

    return await axios.request(options)
}

export const getSubServices = async (serviceId) => {
    const url = `${toolixBaseUrl}/services/${serviceId}`;
    const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token");
    const options = {
        method: 'get',
        url,
        headers: {
            'Authorization': token
        }
    };

    return await axios.request(options)
}

export const getPurchases = async () => {
    const url = `${toolixBaseUrl}/purchase/credits`;
    const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token");
    const options = {
        method: 'get',
        url,
        headers: {
            'Authorization': token
        }
    };
    return await axios.request(options)
}

export const purchaseInitPay = async (payload) => {
    const url = `${toolixBaseUrl}/purchase/init-pay`;
    const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token");
    const config = {
        headers: {
            'Authorization': token
        }
    }
    return axios.post(url, payload , config)
}

export const toolixImageProcessor = async (workName, serviceId, file) => {
    const toolixUrl = `${toolixBaseUrl}/cutout/upload-and-process-image`
    const url = "https://www.cutout.pro/api/v1/" + cutoutDeterminantWork(workName)
    const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token");
    const formData = new FormData()
    const config = {
        headers: {
            'Authorization': token
        }
    }

    formData.append("file", file)
    formData.append("cutout_url", url)
    formData.append("sub_service_id", serviceId)

    return axios.post(toolixUrl, formData , config)
}

export const uploadRecipient = async (payload) => {
    const url = `${toolixBaseUrl}/purchase/upload-recipient`;
    const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token");
    const formData = new FormData()

    formData.append("transaction_id", Number(payload.transaction_id))
    formData.append("user_id", Number(payload.user_id))
    formData.append("paid_amount", parseFloat(payload.paid_amount))
    formData.append("received_credit", parseFloat(payload.received_credit))
    if (payload.proof !== null) formData.append("proof", String(payload.proof))
    if (payload.file !== null) formData.append("file", payload.file)

    const config = {
        headers: {
            'Authorization': token,
        }
    }

    return axios.post(url, formData , config)
}

export const cancelPurchase = async (transaction_id) => {
    const url = `${toolixBaseUrl}/purchase/cancel-purchase/${transaction_id}`;
    const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token");

    const options = {
        method: 'put',
        url,
        headers: {
            'Authorization': token
        }
    };

    return axios.request(options)
}
// Toolix API methods end


// Cutout methods start
function cutoutTokenGenerator() {
    return "403201de4023491e9e4b960ee0a13496"
}

function cutoutDeterminantWork(serviceName) {
    let result
    //  "cartoonize" "enhancer" "oldphoto_recovery" "bg_remover"

    if (serviceName === "cartoonize") result = "cartoonSelfie2?cartoonType=7"
    if (serviceName === "enhancer") result = "photoEnhance2"
    if (serviceName === "bg_remover") result = "matting2?mattingType=6&crop=true"

    return result
}

export async function cutoutAPI(workName, file) {
    if (!file) throw new Error("file not founded!")
    const baseCutOutBaseUrl = "https://www.cutout.pro/api/v1/"
    const url = baseCutOutBaseUrl + cutoutDeterminantWork(workName)
    const formData = new FormData();

    formData.append('file', file);

    const config = {
        headers: {
            'APIKEY': cutoutTokenGenerator(),
            'Content-Type': 'multipart/form-data'
        },
        // responseType: "blob"
    };

    return await axios.post(url, formData, config);

}

export const fetchCutoutCreditBalance = async () => {
    const url = `https://www.cutout.pro/api/v1/mySubscription`;
    // const token = window.localStorage.getItem("token_type") + " " + window.localStorage.getItem("token");
    const options = {
        method: 'get',
        url,
        headers: {
            'APIKEY': cutoutTokenGenerator(),
            'Content-Type': 'multipart/form-data'
        }
    };
    return await axios.request(options)
}
// Cutout methods end

