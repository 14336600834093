import Navbar from "../Components/Navbar";
import BaseCard from "../Components/BaseCard";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {getSubServices} from "../HttpClient/index";
import {useNavigate} from "react-router-dom";

function PhotoServices() {
    const { t } = useTranslation();
    const [data, setData] = useState(null)
    const navigate = useNavigate()
    const serviceId = 1

    useEffect(() => {
        getSubServices(serviceId)
            .then(response => setData(response.data))
            .catch(error => {
                const msg = t('errors.sub_services')

                navigate("/error",{state: {errorData: {msg}}})
            })
    }, [])

    const services = data && data.map(service => {
        return <BaseCard key={service.id} cardData={service} />
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <section className={"homepage"}>
            <Navbar actions={[{action:"back", title: t("actions.back")}]} />
            <div className={'services'}>
                {services}
            </div>
        </section>
    )
}

export default PhotoServices