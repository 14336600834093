import {useState} from "react";
import {useTranslation} from "react-i18next";

function FileQualityList({callbak, data}) {
    const {t} = useTranslation()
    const [showList, setShowList] = useState(false)
    const [currentQuality, setCurrentQuality]= useState(null)
    const sortedData = data && data.sort((a,b) => a.quality - b.quality)

    const listVisibilityHandler = () => setShowList(!showList)

    const setQuality = (event) => {
        const currentElement = event.currentTarget
        const format = currentElement.getAttribute("data-format")
        const quality = currentElement.getAttribute("data-quality")
        const identifier = currentElement.getAttribute("data-identifier")

        callbak({quality, format, identifier})
        setCurrentQuality(`${quality}/${format}`)
        setShowList(false)
    }

    return (
        <div className={`file-quality ${showList && "showList"}`}>
            <button className={"file-quality__action"} onClick={listVisibilityHandler}>
                {currentQuality ? currentQuality : t("actions.select_quality")}
            </button>
            <div className={"quality-list"}>
                <ul className={"inner"}>
                {
                    sortedData.map((item,i) => {
                        return(
                            <li className={"quality-list__item"} onClick={setQuality} key={item.quality+i}
                                data-identifier={item.identifier} data-quality={item.quality} data-format={item.format}
                            >
                                <span>{item.quality}</span>
                                <span>{item.format}</span>
                            </li>
                        )
                    })
                }
                </ul>
            </div>
        </div>
    )
}

export default FileQualityList