import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function ConverterItem({data}) {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const clickHandler = () => navigate(data.route)

    return (
        <div className={"converter-item"} onClick={clickHandler}>
            <div className={"converter-item__title"}>{t(`pages.converters.${data.title}`)}</div>
        </div>
    )
}

export default ConverterItem