import i18next from 'i18next';
import { useTranslation, initReactI18next } from "react-i18next";
import EN from "./EN";
import FA from "./FA";

const htmlElement = document.getElementsByTagName("HTML")[0]


function systemLanguageHandler(systemLanguage) {
    if (systemLanguage==="en") {
        htmlElement.setAttribute('lang',systemLanguage)
        htmlElement.setAttribute("dir", "ltr")
        window.localStorage.setItem("lang", "en")
    } else if (systemLanguage==="fa") {
        htmlElement.setAttribute('lang',systemLanguage)
        htmlElement.setAttribute("dir", "rtl")
        window.localStorage.setItem("lang", "fa")
    } else {
        htmlElement.setAttribute('lang',"en")
        htmlElement.setAttribute("dir", "ltr")
        window.localStorage.setItem("lang", "en")
    }

    i18next
        .use(initReactI18next)
        .init({
            lng: systemLanguage,
            fallbackLng: systemLanguage,
            interpolation: {
                escapeValue: false,
            },
            resources: {
                en: {
                    translation: EN
                },
                fa: {
                    translation: FA
                }
            }
        });
}

export default systemLanguageHandler