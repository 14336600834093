import PageTitle from "../Components/PageTitle";
import Navbar from "../Components/Navbar";
import {useTranslation} from "react-i18next";
import { ReactSVG } from 'react-svg'
import PrimaryButtonOutline from "../Components/PrimaryButtonOutline";
import CTAButton from "../Components/CTAButton";
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from "react";
import FileQualityList from "../Components/FileQualityList";
import {youtubeInfoDownloader, youtubeDownloader, instagramDownloader, soundcloudDownloader, radiojavanDownloader} from "../HttpClient/index";
import Loader from "../Components/Loader";

const faServiceName = {
    youtube: "یوتوب",
    radiojavan: "رادیو جوان",
}

function PasteLink() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const {serviceName} = useParams()
    const [inputValue, setInputValue] = useState("")
    const [error, setError] = useState(null)
    const [downloadInformation, setDownloadInformation] = useState(null)
    const [data, setData]= useState(null)
    const [thumbnail, setThumbnail] = useState(null)
    const [qualityList, setQualityList] = useState(null)
    const [isLoading,setIsLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const downloader = (url) => {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const clickHandler = () => {
        setIsLoading(true)

        if (serviceName === "youtube" && downloadInformation && downloadInformation.identifier) {
            youtubeDownloader(data.VideoID, downloadInformation.identifier)
                .then(res => {
                    const userAgent = navigator.userAgent.toLowerCase();

                    navigate("/proccessComplete")
                    if (/android/.test(userAgent)) window.open(res.data.url, '_system');
                    else if (/iphone|ipad|ipod/.test(userAgent)) window.Telegram.WebApp.openLink(res.data.url);
                    else window.open(res.data.url, '_blank');

                })
                .catch(err => navigate('/error', {state: {errorData: {msg: t("errors.prepare_download_link"), obj: err.data}}}))
                .finally(() => setIsLoading(false))
        }

        if (serviceName === "radiojavan") {
            const DownloadURL = downloadInformation.identifier

            navigate("/proccessComplete")
            window.Telegram.WebApp.openLink(DownloadURL)
            setIsLoading(false)
        }
    }

    const internalUrlValidation = (url) => {
        const urlRegex = /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;

        if (urlRegex.test(url)) {
            setError(null)
            return  true
        } else {
            setError(t("errors.url"))
            return false
        }
    }


    const paster = async () => {
        if (inputValue && internalUrlValidation(inputValue)) {
            setIsLoading(true)
            setInputValue(inputValue)

            if (serviceName === "youtube") {
                youtubeInfoDownloader(inputValue)
                    .then(res => {
                        const qualityList = []

                        res.data.Streams.map(stream => {
                            const tempObj = {}
                            tempObj["identifier"] = stream.itag
                            tempObj["quality"] = stream.resolution
                            tempObj["format"] = stream.mime_type

                            qualityList.push(tempObj)
                        })

                        setData(res.data)
                        setThumbnail(res.data.Thumbnail)
                        setQualityList(qualityList)
                     })
                    .catch(err => {
                        navigate('/error', {state: {errorData: {msg: t("errors.youtube_info"), obj: err.data}}})
                    })
                    .finally(() => setIsLoading(false))


                return
            }

            if (serviceName === "instagram") {
                instagramDownloader(inputValue)
            }

            if (serviceName === "soundcloud") {
                soundcloudDownloader(inputValue)
                    .then(res => {
                        const downloadUrl = res.data.audio[0].url + "&attachment";

                        window.tele.openLink(downloadUrl)
                    })
                    .catch(err => {
                        navigate('/error', {state: {errorData: {msg: t("errors.wrong")}}})
                    })
                    .finally(() => setIsLoading(false))
            }

            if (serviceName === "radiojavan") {
                radiojavanDownloader(inputValue)
                    .then(res => {
                        const downloadLink = res.data.hq_link ? res.data.hq_link : res.data.lq_link
                        setThumbnail(res.data.thumbnail)
                        setDownloadInformation({identifier: downloadLink})
                    })
                    .catch(err => {
                        navigate('/error', {state: {errorData: {msg: t("errors.wrong")}}})
                    })
                    .finally(() => setIsLoading(false))
            }
        }
    }

    const downloadInformationExtractor = (data) => setDownloadInformation(data)

    const globalServiceName = window.localStorage.getItem("lang") === "en" ? `${serviceName}` : `${faServiceName[serviceName]}`

    return (
        <div className={"paste-link"}>
            {isLoading && <Loader />}
            <Navbar actions={[{action:"back", title: t("actions.back")}]}/>
            <div className={"paste-link__title-section"}>
                <PageTitle title={t("paste_link")} />
            </div>
            <div className={"paste-link__content"}>
                {
                    thumbnail ?
                        <img src={thumbnail} alt=""
                            style={{width: "200px", height: "120px", margin: "0 auto"}}
                        />
                        :
                        <img src={`/Images/Webp/${serviceName}Logo.webp`} width={70} height={60} />
                }
                <div className={"paste-link__form-group"}>
                    <input className={"paste-link__input"} onChange={(e) => setInputValue(e.target.value)}
                           value={inputValue} type="text"
                           placeholder={t("url_placeholder", {serviceName: globalServiceName})}
                           onPaste={paster}
                    />
                    {inputValue && <PrimaryButtonOutline callback={paster} title={t("actions.goto_link")}/>}
                    {
                        qualityList &&
                        <FileQualityList data={qualityList} callbak={downloadInformationExtractor} />
                    }
                    {error && <small className={"error-text"}>{error}</small>}

                </div>
                {
                    downloadInformation &&
                    <div className={"upload-image__action-part"}>
                        <CTAButton title={t("actions.download")} calback={clickHandler}/>
                    </div>
                }

            </div>
        </div>
    )
}

export default PasteLink