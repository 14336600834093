import React, { createContext, useReducer } from 'react';

const initialState = {
    isProduction: true,
    userInfo: {},
    serviceInfo: {},
    telegramDataUnsafe: null,
    telegramInitData: null,
    tempUserInfo : {
        "id": 1504,
        "chat_id": 324871118,
        "name": "Masoud",
        "lastname": null,
        "username": "msdshojaeiii",
        "credit_balance": 2936000,
        "daily_download_count": 0,
        "referred_by": null,
        "referral_count": 0,
        "referral_link": "https://t.me/ToolixBot?start=MTUwNA",
        "pref_language": "fa",
        "avatar": "default",
        "is_local": true,
        "has_pending_deposit": true,
        "pending_deposits": [
            {
                "user_id": 1504,
                "paid_amount": 2,
                "received_credit": 10000,
                "id": 1,
                "transaction_type": "DEPOSIT",
                "transaction_status": "PENDING",
                "proof": null,
                "proof_type": null,
                "created_at": "2024-06-18T12:52:05.630662"
            },
            {
                "user_id": 1504,
                "paid_amount": 420000,
                "received_credit": 50000,
                "id": 2,
                "transaction_type": "DEPOSIT",
                "transaction_status": "PENDING",
                "proof": null,
                "proof_type": null,
                "created_at": "2024-06-18T12:52:32.081415"
            },
        ]
    },
    tempServicesInfo : [
        {
            "id": "profile",
            "name": "profile"
        },
        {
            "id": 1,
            "name": "photo"
        },
        {
            "id": 2,
            "name": "downloader"
        },
        {
            "id": 3,
            "name": "converter"
        },
        {
            "id": "support",
            "name": "support"
        }
    ],
};

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_USER_INFO":
            return {
                ...state,
                userInfo: action.payload
            }
        case "SET_SERVICE_INFO":
            return {
                ...state,
                serviceInfo: action.payload
            }
        case "SET_TELEGRAM_DATA_UNSAFE":
            return {
                ...state,
                telegramDataUnsafe: action.payload
            }
        case "SET_TELEGRAM_INIT_DATA":
            return {
                ...state,
                telegramInitData: action.payload
            }
        default:
            return state;
    }
};

export const GlobalStateContext = createContext();
export const GlobalStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <GlobalStateContext.Provider value={{ state, dispatch }}>
            {children}
        </GlobalStateContext.Provider>
    );
};