import {useTranslation} from "react-i18next";
import Navbar from "../Components/Navbar";
import CTAButton from "../Components/CTAButton";
import {useContext, useEffect, useState} from "react";
import {userInfo, updateProfile} from "../HttpClient";
import Loader from "../Components/Loader";
import {useNavigate} from "react-router-dom";
import Man1Icon from "../SvgComponents/Avatars/Man1";
import Man2Icon from "../SvgComponents/Avatars/Man2";
import Woman1Icon from "../SvgComponents/Avatars/Woman1";
import Woman2Icon from "../SvgComponents/Avatars/Woman2";
import BaseButtonSm from "../Components/BaseButtonSm";
import CoinIcon from "../SvgComponents/Coin";
import ConverterICon from "../SvgComponents/Converters";
import Language from "../SvgComponents/Language";
import systemLanguageHandler from "../lang";
import {GlobalStateContext} from "../Context/GlobalStateContext";

function Profile() {
    const {t} = useTranslation()
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [referralTitle, setReferralTitle] = useState(t("pages.profile.referrals"))
    const [isExpandBoard, setIsExpandBoard]  = useState(false)
    const [boardState , setBoardState] = useState("")
    const [avatarName, setAvatarName]  = useState("woman1")
    const [renderPermissionOfAvatarItems, setRenderPermissionOfAvatarItems]  = useState(true)
    const [currentLanguage, setCurrentLanguage] = useState("")
    const navigate = useNavigate();
    const {state, dispatch} = useContext(GlobalStateContext)

    useEffect(() => {
        const userInfoExist = Boolean(Object.keys(state.userInfo).length)

        window.scrollTo(0, 0)
        if (userInfoExist) {
            setData(state.userInfo)
            setCurrentLanguage(state.userInfo.pref_language)
            setAvatarName(state.userInfo.avatar)
        }
        else fetchUserInfo()
    }, []);

    const fetchUserInfo = () => {
        setIsLoading(true)
        userInfo()
            .then(res => {
                const storedAvatarName = res.data.avatar ? (res.data.avatar === "default" ? "man1" : res.data.avatar) : "man1";

                setData(res.data)
                setAvatarName(storedAvatarName)
                setCurrentLanguage(res.data.pref_language)
            })
            .catch(err => {
                const errMessage = t("errors.user_info")

                navigate("/error",{state: {errorData: {msg: errMessage, obj: err.data, retryRout:"/"}}})
            })
            .finally(() => setIsLoading(false))
    }

    const copyReferral = () => {
        navigator.clipboard.writeText(data.referral_link)
        setReferralTitle(t("copied"))
        setTimeout(() => setReferralTitle(t("pages.profile.referrals")),2000)
    }

    const increaseCredit = () => {
        // if (data && data.is_local) {
        //     updateProfile({avatar: data.avatar, language: data.pref_language, is_local:null})
        //     navigate("payment",{state: {userData: {data}}})
        //     return
        // }

        window.Telegram.WebApp.showPopup({
            title: t("chose_location"),
            message: t("chose_location_description"),
            buttons: [
                { id: 'local', type: 'default', text: t("pages.profile.local") },
                { id: 'nonLocal', type: 'default', text: t("pages.profile.non_local") },
                { id: 'cancel', type: 'destructive', text: t("actions.cancel") }
            ]
        }, (buttonId) => {
            if (buttonId !== 'cancel') {
                const is_local = buttonId === "local"

                setIsLoading(true)

                updateProfile({avatar: data.avatar, language: data.pref_language, is_local})
                    .then(updateProfileResponse => navigate("payment",{state: {userData:is_local}}))
                    .catch(() => alert(t("errors.update_profile")))
                    .finally(() => setIsLoading(false))

            }
        })

    }

    const expandTheBoard = () => setIsExpandBoard(true)

    const collapseTheBoard = () => setIsExpandBoard(false)

    const avatarChangeHandler = () => {
        if (isExpandBoard) {
            updateProfile({avatar: avatarName, language: currentLanguage})
                .catch(() => {
                    const message = t('errors.update_avatar')

                    window.tele.showAlert(message)
                })
            setBoardState("")
            collapseTheBoard()

            return
        }

        expandTheBoard()
        setBoardState("edit-avatar")
    }

    const avatarDetector = () => {
        return avatarName === "man1" ? <Man1Icon className={"avatar"}/> : avatarName === "man2" ? <Man2Icon className={"avatar"}/> :
            avatarName === "woman1" ? <Woman1Icon className={"avatar"}/> : <Woman2Icon className={"avatar"}/>
    }

    const setNewAvatar = (event) => {
        const name = event.currentTarget.getAttribute("data-name")

        setAvatarName(name)
        setRenderPermissionOfAvatarItems(false)
        setTimeout(() => setRenderPermissionOfAvatarItems(true),0)
    }

    const languageChanger = () => {
        if (isExpandBoard) {
            updateProfile({avatar: avatarName, language: currentLanguage})
                .then(() => {
                    systemLanguageHandler(currentLanguage)
                    setTimeout(() => setReferralTitle(t("pages.profile.referrals")),300)
                })
                .catch(() => window.tele.showAlert(t('errors.update_language')))

            setTimeout(() =>  collapseTheBoard(),400)
            setBoardState("")

            return
        }
        setTimeout(() =>  expandTheBoard(),400)
        setBoardState("change-language")
    }

    const languageListHandler = (event) => {
        const language = event.target.innerText
        setCurrentLanguage(language)
    }

    const changeLanguageEl = <BaseButtonSm action={languageChanger}>
        <Language/>
        <span>{currentLanguage && currentLanguage}</span>
    </BaseButtonSm>

    return (
        <div className={'my-profile'}>
            {
                isLoading ?
                    <Loader />
                    :
                    <>
                        <Navbar navItems={changeLanguageEl} actions={[{action:"back", title: t("actions.back")}]}/>
                        <div className={"my-profile__content"}>
                            <div className={`smart-board ${isExpandBoard && "smart-board--expanded"} ${boardState}`}>
                                <div className={"smart-board__collapse-content"}>
                                    {avatarDetector()}
                                    {
                                        !isExpandBoard &&
                                        <button className={"edit-profile-avatar"} onClick={avatarChangeHandler}>
                                            <ConverterICon />
                                        </button>
                                    }
                                </div>
                                <div className={"smart-board__expand-content"}>
                                    {
                                        (isExpandBoard && boardState === "edit-avatar") &&
                                            <>
                                                <ul className={"avatar-list"}>
                                                    <li className={`avatar-list__item ${avatarName==="man1" ? "active" : ""}`} data-name={"man1"} onClick={setNewAvatar}>
                                                        {renderPermissionOfAvatarItems && <Man1Icon/>}
                                                    </li>
                                                    <li className={`avatar-list__item ${avatarName==="man2" ? "active" : ""}`} data-name={"man2"} onClick={setNewAvatar}>
                                                        {renderPermissionOfAvatarItems && <Man2Icon/>}
                                                    </li>
                                                    <li className={`avatar-list__item ${avatarName==="woman1" ? "active" : ""}`} data-name={"woman1"} onClick={setNewAvatar}>
                                                        {renderPermissionOfAvatarItems && <Woman1Icon/>}
                                                    </li>
                                                    <li className={`avatar-list__item ${avatarName==="woman2" ? "active" : ""}`} data-name={"woman2"} onClick={setNewAvatar}>
                                                        {renderPermissionOfAvatarItems && <Woman2Icon/>}
                                                    </li>
                                                </ul>
                                                <button className={"btn--board"} onClick={avatarChangeHandler}>
                                                    {t("actions.save")}
                                                </button>
                                            </>
                                    }
                                    {
                                        (isExpandBoard && boardState === "change-language") &&
                                        <div className={"language"}>
                                            <div className={"left-side"}>{t("select_language")}</div>
                                            <div className={"right-side"}>
                                                <ul className={"language-list"}>
                                                    <li className={`language-list__item ${currentLanguage==="fa" && "active"}`} onClick={languageListHandler}>fa</li>
                                                    <li className={`language-list__item ${currentLanguage==="en" && "active"}`} onClick={languageListHandler}>en</li>
                                                </ul>
                                                <button className={"btn--board"} onClick={languageChanger}>{t("actions.save")}</button>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className={"profile-statistic"}>
                                <div className={"profile-statistic__important-data"}>
                                    <div className={"my-profile__mini-box"}>
                                        <div className={"my-profile__mini-box-info"}>
                                            <div className={"my-profile__key"}>{t("pages.profile.token")}</div>
                                            <div className={"my-profile__value"}>{data?.credit_balance ? data.credit_balance : 0}</div>
                                        </div>
                                        <CoinIcon className={"my-profile__mini-box-icon"}/>
                                    </div>
                                    <div className={"my-profile__mini-box my-profile__mini-box--referral"} onClick={copyReferral}>
                                        <div className={"my-profile__mini-box-info"}>
                                            <div className={"my-profile__key"}>{referralTitle}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"profile-statistic__data"}>
                                    <div className="my-profile__box">
                                        <div className={"my-profile__box-info"}>
                                            <div className={"my-profile__key"}>{t("username")}</div>
                                            <div className={"my-profile__value"}>{data?.username ? data.username : "--"}</div>
                                        </div>
                                        <div className={"my-profile__box-info"}>
                                            <div className={"my-profile__key"}>{t("pages.profile.download_count")}</div>
                                            <div className={"my-profile__value"}>{data?.daily_download_count ? data.daily_download_count : 0}</div>
                                        </div>
                                        {/*<div className={"my-profile__box-info"}>*/}
                                        {/*    <div className={"my-profile__key"}>{t("pages.profile.registry_date")}</div>*/}
                                        {/*    <div className={"my-profile__value"}>{data.JoinOn ? data.JoinOn.split(":")[0] : "--"}</div>*/}
                                        {/*</div>*/}
                                        <div className={"my-profile__box-info"}>
                                            <div className={"my-profile__key"}>{t("pages.profile.referrals_count")}</div>
                                            <div className={"my-profile__value"}>{data?.referral_count ? data.referral_count : 0}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"referral-link"}>
                                <CTAButton title={t("actions.increase_credit")} calback={increaseCredit} />
                            </div>
                        </div>
                    </>
            }

        </div>
    )
}

export default Profile