function ConverterList({title, children}) {
    return (
        <div className={"converter-list"}>
            <h3 className={"converter-list__title"}>{title}</h3>
            <div>
                {children}
            </div>
        </div>
    )
}

export default ConverterList