export default {
    "services" : {
        "profile": "پروفایل من",
        "cartoonize": "کارتونی کردن عکس",
        "enhancer": "بهبود دهنده عکس",
        "oldphoto_recovery": "بازیابی عکس قدیمی",
        "bg_remover": "حذف پس زمینه",
        "photo": "ویرایشگرهای عکس",
        "downloader": "دانلودر ها",
        "converter": "مبدل ها",
        "support": "پشتیبانی",
        "radio_javan": "رادیو جوان",
        "instagram": "اینستاگرام",
        "sound_cloud": "ساندکلاد",
        "youtube": "یوتوب",
    },
    "actions": {
        "back": "برگشتن",
        "save": "ذخیره",
        "close": "بستن",
        "retry": "تلاش مجدد",
        "continue": "ادامه",
        "message_to_support": "پیام به پشتیبانی",
        "paste": "الصاق",
        "download": "دانلود",
        "send": "ارسال",
        "select_quality": "انتخاب کیفیت",
        "referral_link": "لینک دعوت",
        "increase_credit": "افزایش اعتبار",
        "goto_link": "برو به لینک",
        "submit" : "ثبت",
        "cancel" : "لغو",
    },
    "errors": {
        "error": "خطا!",
        "not_enough_credit": "اعتبار شما برای استفاده از این سرویس کافی نیست",
        "url": "لطفا آدرس معتبر وارد کنید !",
        "wrong": "خطایی رخ داده است",
        "user_info": "دریافت اطلاعات کاربر با خطا مواجه  شد",
        "token_not_found": "توکن اعتبارسنجی یافت نشد",
        "update_avatar": "خطا در بروزرسانی آواتار! لطفا مجددا تلاش کنید",
        "update_language": "خطا در بروزرسانی زبان! لطفا مجددا تلاش کنید",
        "update_profile": "خطا در بروزرسانی اطلاعات کاربر! لطفا مجددا تلاش کنید",
        "prepare_download_link" : "مشکلی در آماده سازی لینک دانلود بوجود آمده!",
        "youtube_info": "مشکلی در دریافت اطلاعات این لینک  بوجود آمده!",
        "sub_services": "خطا در دریافت لیست خدمات!",
        "photo_services_server": "مشکلی در برقراری ارتباط با سرویس دهنده عکس پیش آمده است!",
        "init_pay": "مشکلی در دریافت جزئیات صورت حساب بوجود آمده است. لطفا مجدد تلاش کنید و در صورتی که مشکل ادامه پیدا کرد  به پشتیبانی پیام بدهید و مشکل را در میان بگذارید.",
        "bill_canceled" : "خطایی در لغو صورتحساب بوجود آمده است.",
        "bill_upload_receipt" : "خطایی در بارگذاری رسید بوجود آمده است, لطفا مجدد تلاش کنید.",
        "vanceai_upload_error": "آپلود عکس با مشکل مواجه شده است. لطفا مطمئن شوید که فرمت عکس شما JPG یا PNG باشد و سایز عکس هم کمتر از ۱۰ مگابایت باشد سپس مجدد تلاش کنید",
        "vanecai_process_step1": "در مرحله اول پردازش مشکلی پیش آمده لطفا مجدد تلاش کنید توکنی از حساب شما کم نشده است",
        "vanecai_process_step2": "در مرحله دوم پردازش مشکلی پیش آمده لطفا مجدد تلاش کنید توکنی از حساب شما کم نشده است",
        "vanecai_download": "مشکلی در دانلود فایل بوجود آمده لطفا اول گالری عکس ها و فایل دانلود های خود را چک کنید در صورتی که فایل مورد نظر خود را یافت نکردید کد زیر را برای پشتیبانی ارسال کنید تا مشکل شما بررسی گردد",
    },
    "pages": {
        "profile": {
            "token": "توکن",
            "referrals": "کپی لینک دعوت",
            "referrals_count": "تعداد دعوت",
            "download_count": "تعداد دانلودها",
            "registry_date": "تاریخ ثبت نام",
            "local": "ایران",
            "non_local": "بین المللی",
        },
        "payment": {
            "currency_toman": "تومان",
            "payment_method": "روش پرداخت",
            "payment_zaring": "زرین پال",
            "payment_paypal": "پی پل",
            "payment_cripto": "کریپتو",
            "payment_card_to_card": "کارت به کارت",
        },
        "upload_image": {
            "loading": "لطفا صبر کنید عکس شما در حال پردازش هست, این فرایند ممکن است کمی طول بکشد لطفا برنامه را نبندید",
            "format_guide": "فرمت فایل باید JPG یا PNG باشد.",
            "size_format": "اندازه فایل نمی تواند بزرگتر از 10 مگابایت باشد و حداکثر وضوح آن 34 مگاپیکسل است.",
            "require_token": "تعداد سکه مورد نیاز برای این سرویس:",
            "token_inventory": "تعداد سکه های شما:",
            "prepare_download_link": "در حال آماده سازی لینک دانلود",
        },
        "bill": {
            "total_payment": "مبلغ کل",
            "token_number": "تعداد توکن",
            "trans_id": "شناسه تراکنش",
            "date": "تاریخ",
            "desc": "توضیحات",
            "desc_desc": "لطفا مبلغ {{price}} {{currency}} را به شماره کارت زیر واریز نمایید سپس عکس رسید خود را در قسمت پایین آپلود نمایید",
            "card_number": "شماره کارت",
            "card_number_copy": "کپی شماره کارت",
            "name": "نام",
            "upload": "رسید خود را از یکی از روش های زیر آپلود کنید",
            "string_receipte": "متن",
            "photo_receipte": "عکس",
            "bill_canceled" : "صورتحساب لغو شد.",
            "bill_upload": "پس از بررسی صورت حساب در کمترین زمان حساب شما شارژ خواهد شد",
        },
        "homepage": {
            "loading": "در حال بارگزاری"
        },
        "converters": {
            "type":"اینجا بنویسید",
            "weight": "وزن",
            "temperature": "درجه حرارت",
            "kilogram": "کیلوگرم",
            "pounds": "پوند",
            "kilogram_to_pounds": "کیلوگرم به پوند",
            "pounds_to_kilogram": "پوند به کیلوگرم",
            "celsius_to_fahrenheit": "سلسیوس به فارنهایت",
            "fahrenheit_to_celsius": "فارنهایت به سلسیوس",
        },
    },
    "success": "موفقیت",
    "username" : "نام کاربری",
    "upload_image": "عکس خود را آپلود کنید",
    "bill": "صورت حساب شما",
    "preview": "پیش نمایش",
    "converters": "مبدل ها",
    "go_to_home": "برو به صفحه اصلی",
    "select_platform": "انتخاب پلتفرم",
    "paste_link": "الصاق لینک",
    "download_process": "فرایند دانلود",
    "support": "به پشتیبانی پیام دهید",
    "select_token": "انتخاب تعداد توکن",
    "text_area_placeholder": "پیام خود را بنویسید ...",
    "copied": "کپی شد",
    "select_language": "زبان خود را انتخاب کنید",
    "url_placeholder": "لینک {{serviceName}} خود را وارد کنید.",
    "chose_location": "انتخاب مکان",
    "chose_location_description": "محدوده مکان خود را مشخص کنید",
    "chose_payment_method": "روش پرداخت",
    "chose_payment_method_description": "روش پرداخت خود را انتخاب کنید",
    "coin_needed": "سکه نیاز است"
}