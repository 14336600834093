import { ReactSVG } from 'react-svg'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {toolixImageProcessor, updateProfile} from "../HttpClient/index";
import {useContext, useState} from "react";
import Loader from "./Loader";
import {useTranslation} from "react-i18next";
import {GlobalStateContext} from "../Context/GlobalStateContext";

function TheUploadeButton() {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [loaderTitle, setLoaderTitle] = useState(t("pages.upload_image.loading"))
    const {state, dispatch} = useContext(GlobalStateContext)

    const uploadHandler = (event) => {
        const {files} = event.target;
        const permissionToContinue = files.length && creditChecker() && formatChecker(files[0])

        if (permissionToContinue) {
            const serviceName = state.serviceInfo.serviceName
            const serviceId = state.serviceInfo.id
            const inputImgUrl = window.URL.createObjectURL(files[0])
            const fileName = files[0].name
            const fileType = files[0].type

            setIsLoading(true)

            toolixImageProcessor(serviceName, serviceId, files[0])
                .then(toolixResponse => {
                    const {image_base64, url} = toolixResponse.data

                    if (image_base64 && url) {
                        // const outputImgUrl = `data:image/png;base64,` + image_base64
                        const outputImgUrl = `data:${fileType};base64,` + image_base64
                        const downloadLink = url

                        navigate('/resultPreview',{state: {files: {inputImgUrl,outputImgUrl, downloadLink, fileName}}})
                    }
                })
                .catch(error => navigate("/error",{state: {errorData: {msg: t("errors.photo_services_server")}}}))
                .finally(() => setIsLoading(false))

        }
    }

    const creditChecker = () => {
        // const userCredit = window.localStorage.getItem("credit_balance")
        const userCredit = state.userInfo.credit_balance
        const servicePrice = state.serviceInfo.servicePrice
        const notEnoughtCredit = Number(userCredit) < Number(servicePrice)

        if (notEnoughtCredit) {
            window.Telegram.WebApp.showPopup({
                title: t("errors.error"),
                message: t("errors.not_enough_credit"),
                buttons: [
                    { id: 'goToIncreaseCredit', type: 'default', text: t("actions.increase_credit") },
                    { id: 'cancel', type: 'destructive', text: t("actions.cancel") }
                ]
            }, (buttonId) => {
                if (buttonId === 'goToIncreaseCredit') navigate("/profile")
            })

            return false
        }
        else {
            return true
        }
    }

    const formatChecker = (file) => {
        const standardFormats = ["image/png", "image/jpeg", "image/jpg", "image/heif", "image/HEIF"]
        const fileType = file.type

        return standardFormats.includes(fileType)
    }

    return (
        isLoading ?
            <Loader title={loaderTitle}/>
            :
            <div className={'upload-image-element'}>
                <label className={"upload-image__label"} htmlFor="imageUploader">
                    <ReactSVG className={"add-icon"} src={"/Images/Svg/add.svg"} />
                </label>
                <input className={'upload-image__input'} type="file" id={"imageUploader"} onChange={uploadHandler} accept="image/png, image/jpeg, image/jpg, image/heif, image/HEIF"/>
            </div>
    )
}

export default TheUploadeButton