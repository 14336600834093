import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle";
import BaseImageWrapper from "../Components/BaseImageWrapper";
import TheUploadeButton from "../Components/TheUploadeButton";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

function UploadImage() {
    const {t} = useTranslation()
    const [enoughCoin, setEnoughCoin] = useState(true)
    const [userCoin, setUserCoin] = useState(1)
    const [serviceCoin, setServiceCoin] = useState(3)
    const navbarDirection = window.localStorage.getItem("lang") === "fa" ? "rtl" : "ltr"

    useEffect(() => {
        window.scrollTo(0, 0)

        if (userCoin < serviceCoin) setEnoughCoin(false)
    }, []);


    return(
        <section className={"upload-image"}>
            <Navbar actions={[{action:"back", title: t("actions.back")}]}>
                {/*<ul className={`token-inventory ${!enoughCoin && "not-enough-coin"}`} style={{direction: navbarDirection}}>*/}
                {/*    <li className={"token-inventory__item"}>*/}
                {/*        <span>{t("pages.upload_image.require_token")}</span>*/}
                {/*        <span >{serviceCoin}</span>*/}
                {/*    </li>*/}
                {/*    <li className={"token-inventory__item"}>*/}
                {/*        <span>{t("pages.upload_image.token_inventory")}</span>*/}
                {/*        <span style={{color: enoughCoin ? "green":"red"}}>{userCoin}</span>*/}
                {/*    </li>*/}
                {/*    <li className={"token-inventory__item increase-coin"}>*/}
                {/*        <Link to={"/profile/payment"}>افزایش اعتبار</Link>*/}
                {/*    </li>*/}
                {/*</ul>*/}
            </Navbar>
            <div className={"upload-image__title-part"}>
                <PageTitle title={t("upload_image")} />
            </div>
            <div className={"upload-image__content"}>
                <div className={"upload-image__image-part"}>
                    <BaseImageWrapper format={"svg"} currentFileName={'upload'}/>
                </div>
                <div className={"upload-image__uploader-part"}>
                    <TheUploadeButton/>
                </div>
                <ul className={"upload-image__guideline"}>
                    <li className={"upload-image__guideline-item"}>{t("pages.upload_image.format_guide")}</li>
                    <li className={"upload-image__guideline-item"}>{t("pages.upload_image.size_format")}</li>
                </ul>
            </div>
        </section>
    )
}

export default UploadImage