import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={70}
        height={70}
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_286_2732)">
            <g filter="url(#filter0_di_286_2732)">
                <path
                    d="M35.0002 67.6401C53.4614 67.6401 68.4272 52.6743 68.4272 34.2131C68.4272 15.7519 53.4614 0.786133 35.0002 0.786133C16.539 0.786133 1.57324 15.7519 1.57324 34.2131C1.57324 52.6743 16.539 67.6401 35.0002 67.6401Z"
                    fill="url(#paint0_linear_286_2732)"
                />
                <path
                    d="M35.0004 67.4431C53.353 67.4431 68.2307 52.5654 68.2307 34.2128C68.2307 15.8601 53.353 0.982422 35.0004 0.982422C16.6477 0.982422 1.77002 15.8601 1.77002 34.2128C1.77002 52.5654 16.6477 67.4431 35.0004 67.4431Z"
                    stroke="url(#paint1_linear_286_2732)"
                    strokeOpacity={0.5}
                />
            </g>
            <g filter="url(#filter1_d_286_2732)">
                <path
                    d="M35.3931 41.292C42.5603 41.292 48.3706 35.4817 48.3706 28.3144C48.3706 21.1472 42.5603 15.3369 35.3931 15.3369C28.2258 15.3369 22.4155 21.1472 22.4155 28.3144C22.4155 35.4817 28.2258 41.292 35.3931 41.292Z"
                    fill="white"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7432 58.2234C16.0814 50.3783 25.1155 44.9844 35.5572 44.9844C45.6825 44.9844 54.4844 50.0566 58.964 57.5175C52.8909 63.7617 44.3985 67.6404 35 67.6404C25.961 67.6404 17.7599 64.0527 11.7432 58.2234Z"
                fill="white"
            />
            <g filter="url(#filter2_d_286_2732)">
                <path
                    d="M32.2471 32.6406C33.26 33.8277 35.8579 35.4895 38.1459 32.6406"
                    stroke="url(#paint2_linear_286_2732)"
                    strokeWidth={3}
                    strokeLinecap="round"
                />
            </g>
        </g>
        <defs>
            <filter
                id="filter0_di_286_2732"
                x={-2.72998}
                y={-1.51758}
                width={75.4604}
                height={79.4609}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={2} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.298039 0 0 0 0 0.454902 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_286_2732"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_286_2732"
                    result="shape"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={10} />
                <feGaussianBlur stdDeviation={7.5} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
                />
                <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect2_innerShadow_286_2732"
                />
            </filter>
            <filter
                id="filter1_d_286_2732"
                x={18.4155}
                y={13.3369}
                width={33.9551}
                height={33.9551}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={2} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.298039 0 0 0 0 0.454902 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_286_2732"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_286_2732"
                    result="shape"
                />
            </filter>
            <filter
                id="filter2_d_286_2732"
                x={27.7471}
                y={30.1406}
                width={14.8989}
                height={10.5732}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={1.5} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.47451 0 0 0 0 0.14902 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_286_2732"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_286_2732"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_286_2732"
                x1={18.68}
                y1={7.47153}
                x2={49.7474}
                y2={70.5895}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#D4B2FF" stopOpacity={0.1} />
                <stop offset={1} stopColor="#70A9FF" stopOpacity={0.8} />
            </linearGradient>
            <linearGradient
                id="paint1_linear_286_2732"
                x1={21.0724}
                y1={-1.44267}
                x2={47.4427}
                y2={73.9539}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.0260417} stopColor="#E4F9FF" stopOpacity={0.8} />
                <stop offset={1} stopColor="#70A9FF" stopOpacity={0.1} />
            </linearGradient>
            <linearGradient
                id="paint2_linear_286_2732"
                x1={34.0346}
                y1={33.7535}
                x2={37.2557}
                y2={33.8972}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#70A9FF" />
                <stop offset={1} stopColor="#4C74FF" />
            </linearGradient>
            <clipPath id="clip0_286_2732">
                <rect width={70} height={70} fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export default SVGComponent;
