import {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import Navbar from "../Components/Navbar";
import {useLocation, useNavigate} from "react-router-dom";
import PageTitle from "../Components/PageTitle";
import {uploadRecipient} from "../HttpClient/index";
import {GlobalStateContext} from "../Context/GlobalStateContext";
import jalaali from "jalaali-js";
import {Tabs, TabList, TabPanels, Tab, TabPanel, useToast} from '@chakra-ui/react'
import {BackButton, MainButton, useCloudStorage, useHapticFeedback, useInitData, useShowPopup} from "@vkruglikov/react-telegram-web-app"
import Loader from "../Components/Loader";

function Bill() {
    const {t} = useTranslation()
    const toast = useToast()
    const navigate = useNavigate()
    const location = useLocation()
    const {state, dispatch} = useContext(GlobalStateContext)
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenKeyboard, setIsOpenKeyboard] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [imageBase64, setImageBase64] = useState('');
    const [imageFile, setImageFile] = useState("")
    const [receipteString, setReceipteString] = useState('');
    const [proofType, setProofType] = useState('photo');
    const [cartNumberTitle, setCartNumberTitle] = useState(t("pages.bill.card_number_copy"))
    const [currency, setCurrency] = useState()
    const {userIsLocate, paymentData} = location.state

    useEffect(() =>  {
        window.scrollTo(0, 0)

        setCurrency(window.localStorage.getItem("lang") === "en" ? userIsLocate ? "Toman" : "Dollar" : userIsLocate ? "تومان" : "دلار")
    }, []);

    function convertToJalali(gDate) {
        const date = new Date(gDate);
        const gy = date.getUTCFullYear();
        const gm = date.getUTCMonth() + 1; // Month is 0-based
        const gd = date.getUTCDate();

        const jalaliDate = jalaali.toJalaali(gy, gm, gd);
        return `${jalaliDate.jy}/${jalaliDate.jm < 10 ? '0' + jalaliDate.jm : jalaliDate.jm}/${jalaliDate.jd < 10 ? '0' + jalaliDate.jd : jalaliDate.jd}`;
    }

    const billUploadHandler = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            setImageFile(file)

            reader.onload = (event) => {
                setImageSrc(event.target.result);
                setImageBase64(event.target.result.split(',')[1])
            }

            reader.readAsDataURL(file);
        }
    }

    const copyBankCartNumber = () => {
        const cartNumber = "6219861935607092";

        navigator.clipboard.writeText(cartNumber)
        setCartNumberTitle("کپی شد")

        setTimeout(() => setCartNumberTitle(t("pages.bill.card_number_copy")),1000)
    }

    const submitHandler = () => {
        const payload = {
            transaction_id: paymentData.id,
            user_id: state.userInfo.id,
            paid_amount: paymentData.paid_amount,
            received_credit: paymentData.received_credit,
            proof: proofType === "text" ? receipteString : null,
            file: proofType === "photo" ? imageFile : null,
        }

        setIsLoading(true)

        uploadRecipient(payload)
            .then(res => {
                const caption = t("pages.bill.bill_upload");

                navigate("/proccessComplete",{state: {caption}});

            })
            .catch(err => {
                toast({
                    title: t("errors.error"),
                    description: t("errors.bill_upload_receipt"),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            })
            .finally(() => setIsLoading(false))
    }

    const proofTypeSetter = (type) => setProofType(type)

    const textareaFoucsHandler = () => setIsOpenKeyboard(true)

    const textareaBlurHandler = () => setIsOpenKeyboard(false)

    const textareaChangesHandler = (event) => setReceipteString(event.target.value)

    return (
        isLoading ?
            <Loader />
            :
            <div className={"bill-page"}>
                <Navbar actions={[{action:"back", title: t("actions.back")}]} />
                <div className={"payment__title-part"}>
                    <PageTitle title={t("bill")} />
                </div>
                <div className={`bill ${isOpenKeyboard && "bill--keyboard-opened"}`}>
                    <ul className={"inner"}>
                        <li className={"bill-item bill-item--api-label"}>
                            <div className={"api-label"}>
                                <img className={"api-label__logo"} src="/Images/logo.png" width={37} height={37} alt=""/>
                                <div className={"api-label__title"}>Toolix - API</div>
                            </div>
                        </li>
                        <li className={"bill-item bill-item--total-cash"}>
                            <span className={"bill-item__key"}>{t("pages.bill.total_payment")}:</span>
                            <span className={"bill-item__value"}>
                            {new Intl.NumberFormat("en").format(paymentData.paid_amount)} {currency}
                        </span>
                        </li>
                        <li className={"bill-item"}>
                            <span className={"bill-item__key"}>{t("pages.bill.token_number")}:</span>
                            <span className={"bill-item__value"}>{new Intl.NumberFormat("en").format(paymentData.received_credit)}</span>
                        </li>
                        <li className={"bill-item"}>
                            <span className={"bill-item__key"}>{t("pages.bill.trans_id")}:</span>
                            <span className={"bill-item__value"}>{paymentData.id}</span>
                        </li>
                        <li className={"bill-item"}>
                            <span className={"bill-item__key"}>{t("pages.bill.date")}:</span>
                            <span className={"bill-item__value"}>{convertToJalali(paymentData.created_at)}</span>
                        </li>
                        <li className={"bill-item bill-item--description"}>
                            <span className={"bill-item__key"}>{t("pages.bill.desc")}:</span>
                            <span className={"bill-item__value"}>{t("pages.bill.desc_desc", {price:new Intl.NumberFormat("en").format(paymentData.paid_amount), currency})}</span>
                        </li>
                        <li className={"bill-item bill-item--bank-cart"}>
                            <span className={"bill-item__key"}>{t("pages.bill.card_number")}:</span>
                            <span className={"bill-item__value"} dir="ltr">6219 - 8619 - 3560 - 7092</span>
                            <span className={"bill-item__key"}>{t("pages.bill.name")}:</span>
                            <span className={"bill-item__value"}>محمد شجاعی</span>
                            <button className={"bill-item__action"} onClick={copyBankCartNumber}>{cartNumberTitle}</button>
                        </li>
                        <li className={"bill-item bill-item--upload-bill"}>
                            <span className={"bill-item__key"}>{t("pages.bill.upload")}</span>
                            <div className={"upload-bill"}>
                                <Tabs variant='soft-rounded' colorScheme='green' isFitted className={"upload-tab"}>
                                    <TabList className={"upload-tab-tablist"}>
                                        <Tab onClick={() => proofTypeSetter("photo")}>{t("pages.bill.photo_receipte")}</Tab>
                                        <Tab onClick={() => proofTypeSetter("text")}>{t("pages.bill.string_receipte")}</Tab>
                                    </TabList>
                                    <TabPanels className={"upload-tab-body"}>
                                        <TabPanel>
                                            <div className={"file-uploader"}>
                                                {
                                                    imageSrc ?
                                                        <div className={"receipte-image"}>
                                                            <img src={imageSrc} alt=""/>
                                                        </div>
                                                        :
                                                        <>
                                                            <label htmlFor="uploadBill" className={"upload-bill__upload-element"}>+</label>
                                                            <input type="file" id={"uploadBill"} onChange={billUploadHandler} style={{display: "none"}}/>
                                                        </>

                                                }
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className={"receipte-string"}>
                                                <textarea onFocus={textareaFoucsHandler} onBlur={textareaBlurHandler} onChange={textareaChangesHandler} className={"upload-bill__string-type"} placeholder={t("text_area_placeholder")}></textarea>
                                            </div>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                                {
                                    (receipteString || imageSrc) &&
                                    <MainButton
                                        color="#62dbff"
                                        textColor="#ffffff"
                                        onClick={submitHandler}
                                        text={t("actions.submit")}
                                    />
                                }
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

    )
}

export default Bill