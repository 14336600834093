import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={19}
        height={19}
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.62843 16.5636C9.33602 16.8757 9.15464 16.9018 9.09439 16.9018C9.03413 16.9018 8.85276 16.8757 8.56035 16.5636C8.28795 16.2727 7.99428 15.8068 7.72022 15.1534H10.4685C10.1945 15.8068 9.9008 16.2727 9.62843 16.5636ZM10.8722 13.9877H7.31662C6.97354 12.7847 6.74582 11.306 6.69812 9.67485H11.4906C11.443 11.306 11.2152 12.7847 10.8722 13.9877ZM7.31662 4.07975C6.96539 5.31142 6.73506 6.83196 6.69502 8.5092H11.4938C11.4538 6.83196 11.2234 5.31142 10.8722 4.07975H7.31662ZM7.72022 2.91411C7.99428 2.26063 8.28795 1.79474 8.56035 1.50394C8.85276 1.19177 9.03413 1.16564 9.09439 1.16564C9.15464 1.16564 9.33602 1.19177 9.62843 1.50394C9.9008 1.79474 10.1945 2.26063 10.4685 2.91411H7.72022ZM12.0878 4.07975C12.4225 5.36533 12.6305 6.8794 12.6675 8.5092H16.9979C16.887 6.83582 16.2495 5.30534 15.2484 4.07975H12.0878ZM14.0734 2.91411H11.7272C11.5149 2.33258 11.2727 1.82155 11.0069 1.39653C12.1465 1.67718 13.1886 2.20289 14.0734 2.91411ZM6.4616 2.91411H4.11536C5.00018 2.20289 6.04233 1.67718 7.18187 1.39653C6.91602 1.82155 6.67388 2.33258 6.4616 2.91411ZM2.94033 4.07975H6.10098C5.76635 5.36533 5.55821 6.8794 5.52124 8.5092H1.19079C1.30167 6.83582 1.93922 5.30534 2.94033 4.07975ZM12.0878 13.9877C12.4145 12.7329 12.6205 11.2603 12.6646 9.67485H16.9894C16.8572 11.3027 16.2263 12.7906 15.2484 13.9877H12.0878ZM5.52418 9.67485H1.19938C1.33154 11.3027 1.96251 12.7906 2.94033 13.9877H6.10098C5.77434 12.7329 5.56822 11.2603 5.52418 9.67485ZM11.7272 15.1534C11.5149 15.7349 11.2727 16.2459 11.0069 16.6709C12.1465 16.3904 13.1886 15.8647 14.0734 15.1534H11.7272ZM4.11536 15.1534C5.00018 15.8647 6.04233 16.3904 7.18187 16.6709C6.91602 16.2459 6.67388 15.7349 6.4616 15.1534H4.11536ZM18.1888 9.03374C18.1888 4.04454 14.1171 0 9.09439 0C4.07169 0 0 4.04454 0 9.03374C0 14.0229 4.07169 18.0675 9.09439 18.0675C14.1171 18.0675 18.1888 14.0229 18.1888 9.03374Z"
            fill="url(#paint0_linear_314_300)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_314_300"
                x1={3.93112}
                y1={2.09816}
                x2={12.9935}
                y2={17.596}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E4F9FF" />
                <stop offset={0.505208} stopColor="#70A9FF" />
                <stop offset={1} stopColor="#4C74FF" />
            </linearGradient>
        </defs>
    </svg>
);
export default SVGComponent;
