import Navbar from "../Components/Navbar";
import {useTranslation} from "react-i18next";
import PageTitle from "../Components/PageTitle";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import ChangeIcon from "../SvgComponents/Change";
import * as converterEngine from '../convertersEngine'

function GetConvert() {
    const { t } = useTranslation();
    const {serviceName,category} = useParams()
    const [data, setData] = useState(null)
    const [inputLabel, setInputLabel] = useState("")
    const [outputLabel, setOutputLabel] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [outputValue, setOutputValue] = useState("")
    const [operator, setOperator] = useState(null)
    // const location = useLocation()
    // const navigate = useNavigate()

    useEffect(() => {
        const ExtractedInformation = converterEngine.dataExtractor(category, serviceName)

        setInputLabel(ExtractedInformation.title.split("_")[0])
        setOutputLabel(ExtractedInformation.title.split("_")[2])
        setData(ExtractedInformation)
        setOperator(ExtractedInformation.route.split("/")[1])
        window.scrollTo(0, 0)
    }, [])

    const changeConverters = () => {
        const oppositeServiceName = data.oppositeRoute.split("/")[1]
        const ExtractedOppositeInformation = converterEngine.dataExtractor(category, oppositeServiceName)

        setInputValue("")
        setOutputValue("")
        setInputLabel(ExtractedOppositeInformation.title.split("_")[0])
        setOutputLabel(ExtractedOppositeInformation.title.split("_")[2])
        setData(ExtractedOppositeInformation)
        setOperator(oppositeServiceName)
    }

    const getConvert = (event) => {
        const value = event.target.value
        const convertedValue = converterEngine[operator](value) !== 0 ? converterEngine[operator](value) : ""

        setInputValue(value)
        setOutputValue(convertedValue)
    }

    return(
        <section className={"converters"}>
            <Navbar actions={[{action:"back", title: t("actions.back")}]}/>
            <div className={"converters__title-section"}>
                {data?.title &&  <PageTitle title={t(`pages.converters.${data.title}`)} />}
            </div>
            <div className={"converters__content"}>
                <div className={"converter-group"}>
                    <div className={"converter-group__icon-operator"}>
                        <ChangeIcon  onClick={changeConverters}/>
                    </div>
                    <div className={"converter-group__from"}>
                        <label htmlFor="inputConverter">{t(`pages.converters.${inputLabel}`)}</label>
                        <input id="inputConverter" onChange={getConvert} value={inputValue}  placeholder={t("pages.converters.type")} type="text"/>
                    </div>
                    <div className={"converter-group__to"}>
                        <label htmlFor="outputConverter">{t(`pages.converters.${outputLabel}`)}</label>
                        <input id="outputConverter" type="text" disabled value={outputValue} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GetConvert