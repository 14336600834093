import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./Styles/Style.scss"
import systemLanguageHandler from "./lang/index";
import {GlobalStateProvider} from "./Context/GlobalStateContext";
import { ChakraProvider } from '@chakra-ui/react';

const systemLanguage = window.localStorage.getItem("lang") ? window.localStorage.getItem("lang") : "en"
const root = ReactDOM.createRoot(document.getElementById('root'));
const telegram = window.Telegram.WebApp

systemLanguageHandler(systemLanguage)

window.telegram = telegram
window.telegram.ready()
setInterval(() => {
    if (!window.telegram.isExpanded) window.telegram.expand()
} ,1500)

if (window.localStorage.getItem("data-theme")) {
    const bodyElement = document.querySelector("BODY")

    bodyElement.setAttribute('data-theme',"")
    bodyElement.setAttribute('data-theme',window.localStorage.getItem("data-theme"))
}

root.render(
  <React.StrictMode>
      <GlobalStateProvider>
          <ChakraProvider>
            <App/>
          </ChakraProvider>
      </GlobalStateProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
