import { useNavigate } from "react-router-dom";

function BaseButtonSm({children, action}) {
    const navigate = useNavigate();

    const clickHandler = () => {
        if (action === "back") {
            navigate(-1)
        }
        else if(action === "close") {
            window.Telegram.WebApp.close();
        } else {
            action()
        }
    }

    return <div className={"base-button  base-button--sm base-button--info"} onClick={clickHandler}>
        {children}
    </div>
}

export default BaseButtonSm