import Navbar from "../Components/Navbar";
import BaseImageWrapper from "../Components/BaseImageWrapper";
import PrimaryButton from "../Components/PrimaryButton";
import PrimaryButtonOutline from "../Components/PrimaryButtonOutline";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import ErrorVectorLilac from "../SvgComponents/lilac/ErrorVector";
import ErrorVectorCrimson from "../SvgComponents/crimson/ErrorVector";
import ErrorVectorGreen from "../SvgComponents/green/ErrorVector";
import ErrorVectorOrange from "../SvgComponents/orange/ErrorVector";
import ErrorVectorCyan from "../SvgComponents/cyan/ErrorVector";

function ErrorPage() {
    const {t} = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const errorData = location?.state?.errorData

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const getRedirect = (nextRoute) =>  navigate(nextRoute)

    const vectorSpecifier = () => {
        const currentTheme = window.localStorage.getItem("data-theme");

        return currentTheme === "crimson" ? <ErrorVectorCrimson /> : currentTheme === "green" ? <ErrorVectorGreen/>
            : currentTheme === "orange" ? <ErrorVectorOrange/> : currentTheme === "cyan" ? <ErrorVectorCyan/> : <ErrorVectorLilac />
    }

    const retryHandler = () => {
        if (errorData && errorData.retryRout) {
            getRedirect(errorData.retryRout)
            return
        }

        getRedirect(-1)
    }

    return (
        <section className={"error-page"}>
            <Navbar actions={[{action:"close", title: t('actions.close')}]}/>
            <div className={"error-page__content"}>
                {vectorSpecifier()}
                <div className={"error-page__message"}>{errorData ? errorData.msg : t("errors.wrong")}</div>
            </div>
            <div className={"error-page__action-part"}>
                {errorData && <PrimaryButton callback={retryHandler} title={t("actions.retry")}/>}
                <PrimaryButtonOutline callback={() => getRedirect("/support")} title={t("actions.message_to_support")}/>
            </div>
        </section>
    )
}

export default ErrorPage