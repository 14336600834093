import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={60}
        height={70}
        viewBox="0 0 60 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_286_2710)">
            <g filter="url(#filter0_df_286_2710)">
                <path
                    d="M2.57129 63.0771H20.7856"
                    stroke="url(#paint0_linear_286_2710)"
                    strokeWidth={4}
                    strokeLinecap="round"
                />
            </g>
            <g filter="url(#filter1_df_286_2710)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.5796 53.1569C19.3572 54.74 19.8508 60.4938 20.097 63.1304C20.1583 63.7873 20.9227 64.1861 21.5924 63.915C24.0633 62.9146 29.1296 60.8454 30.3298 60.2185C31.828 59.4358 33.8379 57.645 34.0943 57.4142C34.1086 57.4011 34.1053 57.3804 34.0882 57.3708C34.072 57.3615 34.0684 57.3419 34.0806 57.3285C34.4215 56.9565 34.7377 56.555 35.0242 56.1254C37.6862 52.1361 36.7766 47.2535 32.9926 45.22C29.2087 43.1865 23.9833 44.7719 21.3213 48.7615C21.0866 49.1131 20.8796 49.4719 20.7 49.835C20.6981 49.8388 20.6931 49.84 20.6893 49.8381C20.6857 49.8361 20.6814 49.8365 20.6794 49.8396C20.622 49.93 19.8599 51.1608 19.5796 53.1569Z"
                    fill="url(#paint1_linear_286_2710)"
                />
            </g>
            <g filter="url(#filter2_f_286_2710)">
                <path
                    d="M49.8742 3.8501C50.9007 1.93603 53.4618 1.13109 55.5948 2.05223C57.7274 2.97335 58.6244 5.27172 57.598 7.18579L35.2964 48.7744C34.27 50.6882 31.709 51.4932 29.5762 50.5721C27.4434 49.6509 26.5465 47.3528 27.5728 45.4386L49.8742 3.8501Z"
                    fill="url(#paint2_linear_286_2710)"
                />
            </g>
        </g>
        <defs>
            <filter
                id="filter0_df_286_2710"
                x={-3.42871}
                y={59.0771}
                width={30.2144}
                height={12}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={2} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.47451 0 0 0 0 0.14902 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_286_2710"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_286_2710"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation={0.5}
                    result="effect2_foregroundBlur_286_2710"
                />
            </filter>
            <filter
                id="filter1_df_286_2710"
                x={15.5234}
                y={42.334}
                width={25.0269}
                height={27.667}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={2} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.47451 0 0 0 0 0.14902 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_286_2710"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_286_2710"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation={0.5}
                    result="effect2_foregroundBlur_286_2710"
                />
            </filter>
            <filter
                id="filter2_f_286_2710"
                x={26.1479}
                y={0.670898}
                width={32.875}
                height={51.2822}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation={0.5}
                    result="effect1_foregroundBlur_286_2710"
                />
            </filter>
            <linearGradient
                id="paint0_linear_286_2710"
                x1="-nan"
                y1="-nan"
                x2="-nan"
                y2="-nan"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FF62C0" />
                <stop offset={0.520833} stopColor="#BE35FF" />
                <stop offset={1} stopColor="#7926FF" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_286_2710"
                x1={16.5036}
                y1={69.9315}
                x2={30.5028}
                y2={43.8819}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FF62C0" />
                <stop offset={0.546875} stopColor="#BE35FF" />
                <stop offset={1} stopColor="#7926FF" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_286_2710"
                x1={62.4537}
                y1={-11.4972}
                x2={34.2624}
                y2={54.1869}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#D4B2FF" stopOpacity={0.1} />
                <stop offset={1} stopColor="#70A9FF" stopOpacity={0.8} />
            </linearGradient>
            <clipPath id="clip0_286_2710">
                <rect width={60} height={70} fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export default SVGComponent;
