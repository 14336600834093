import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle";
import BaseImageWrapper from "../Components/BaseImageWrapper";
import CTAButton from "../Components/CTAButton";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import BasePhotoComparator from "../Components/BasePhotoComparator";



function SaveImagePage() {
    const navigate = useNavigate()
    const location = useLocation()
    const {t} = useTranslation()
    const [downlodLink, setDownloadLink] = useState(null)
    const downloadElementRef = useRef()

    useEffect(() => {
        window.scrollTo(0, 0)

        setDownloadLink(location.state.files.downloadLink)

        setTimeout(() => {
            downloadElementRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },500);
    }, []);

    const saveHandler = () => {
        const userAgent = navigator.userAgent.toLowerCase();

        if (/android/.test(userAgent)) window.open(downlodLink, '_system');
        else if (/iphone|ipad|ipod/.test(userAgent)) window.Telegram.WebApp.openLink(downlodLink);
        else window.open(downlodLink, '_blank');

        setTimeout(() => navigate("/proccessComplete"), 1000)
    }

    return (
        <section className={"image-previewer"}>
            <Navbar actions={[{action:"back", title: t("actions.back")}]}/>
            <div className={"upload-image__title-part"}>
                <PageTitle title={t("preview")} />
            </div>
            <div className={"image-previewer__uploaded-imagew"}>
                <BasePhotoComparator currentFileAddress={location.state.files.inputImgUrl} afterFileAddress={location.state.files.outputImgUrl}/>
            </div>
            <div className={"image-previewer__action-part"} ref={downloadElementRef}>
                <CTAButton title={t("actions.save")} calback={saveHandler}/>
            </div>
        </section>
    )
}

export default SaveImagePage