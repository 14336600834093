import ConverterList from "./Components/ConverterList";
import ConverterItem from "./Components/ConverterIteml";

export const convertersCategory = [
    "weight", "temperature"
]

export const converterItems = {
    "weight": [
        {id: 1, title: "kilogram_to_pounds", route: "Weight/KilogramToPounds", oppositeRoute: "Weight/PoundsToKilogram"},
        {id: 2, title: "pounds_to_kilogram", route: "Weight/PoundsToKilogram", oppositeRoute: "Weight/KilogramToPounds"}
    ],
    "temperature": [
        {id: 1, title: "celsius_to_fahrenheit", route: "Temperature/CelsiusToFahrenheit", oppositeRoute: "Temperature/FahrenheitToCelsius"},
        {id: 2, title: "fahrenheit_to_celsius", route: "Temperature/FahrenheitToCelsius", oppositeRoute: "Temperature/CelsiusToFahrenheit"},
    ]
}

export const dataExtractor = (category, serviceName) => {
    const currentRoute = `${category}/${serviceName}`
    const targetObj = converterItems[category.toLowerCase()].find(item => item.route === currentRoute)

    return targetObj
}

export const PoundsToKilogram = (inputValue) => inputValue * 0.453592

export const KilogramToPounds = (inputValue) => inputValue * 2.20462

export const CelsiusToFahrenheit = (inputValue) => ((inputValue * 9.0 / 5.0) + 32.0)
export const FahrenheitToCelsius = (inputValue) => (inputValue - 32) * 5/9