import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={70}
        height={57}
        viewBox="0 0 70 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_286_2717)">
            <g filter="url(#filter0_d_286_2717)">
                <path
                    d="M34.8175 54.765C46.3954 54.765 55.7811 45.1742 55.7811 33.3434C55.7811 21.5126 46.3954 11.9219 34.8175 11.9219C23.2397 11.9219 13.854 21.5126 13.854 33.3434C13.854 45.1742 23.2397 54.765 34.8175 54.765Z"
                    fill="url(#paint0_linear_286_2717)"
                />
            </g>
            <g filter="url(#filter1_d_286_2717)">
                <path
                    d="M28.8022 42.6572C30.929 44.9547 36.495 48.171 41.745 42.6572"
                    stroke="white"
                    strokeWidth={4}
                    strokeLinecap="round"
                />
            </g>
            <path
                d="M9.11475 29.0583C9.11475 20.0529 15.3652 2.37606 35.1749 2.97993C47.396 3.35247 58.8804 12.2936 60.8856 29.0583"
                stroke="url(#paint1_linear_286_2717)"
                strokeWidth={4}
            />
            <g filter="url(#filter2_f_286_2717)">
                <path
                    d="M56.875 27.1958C56.875 26.167 57.6913 25.333 58.6979 25.333H60.5208C65.5546 25.333 69.6354 29.5029 69.6354 34.6467C69.6354 39.7905 65.5546 43.9605 60.5208 43.9605H58.6979C57.6913 43.9605 56.875 43.1263 56.875 42.0977V27.1958Z"
                    fill="url(#paint2_linear_286_2717)"
                />
            </g>
            <path
                d="M57.2397 23.4707H54.6877C53.6809 23.4707 52.8647 24.3047 52.8647 25.3334V43.9609C52.8647 44.9897 53.6809 45.8236 54.6877 45.8236H57.2397C58.2465 45.8236 59.0627 44.9897 59.0627 43.9609V25.3334C59.0627 24.3047 58.2465 23.4707 57.2397 23.4707Z"
                fill="url(#paint3_linear_286_2717)"
            />
            <g filter="url(#filter3_f_286_2717)">
                <path
                    d="M13.1252 27.1958C13.1252 26.167 12.309 25.333 11.3022 25.333H9.47933C4.44549 25.333 0.364746 29.5029 0.364746 34.6467C0.364746 39.7905 4.44549 43.9605 9.47933 43.9605H11.3022C12.309 43.9605 13.1252 43.1263 13.1252 42.0977V27.1958Z"
                    fill="url(#paint4_linear_286_2717)"
                />
            </g>
            <path
                d="M12.7603 23.4707H15.3123C16.3191 23.4707 17.1353 24.3047 17.1353 25.3334V43.9609C17.1353 44.9897 16.3191 45.8236 15.3123 45.8236H12.7603C11.7535 45.8236 10.9373 44.9897 10.9373 43.9609V25.3334C10.9373 24.3047 11.7535 23.4707 12.7603 23.4707Z"
                fill="url(#paint5_linear_286_2717)"
            />
            <path
                d="M18.9585 7.82371C24.0019 4.03615 37.1877 -0.93119 49.4012 7.82371"
                stroke="url(#paint6_linear_286_2717)"
                strokeWidth={14}
                strokeLinecap="round"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_286_2717"
                x={9.854}
                y={9.92188}
                width={49.9272}
                height={50.8428}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={2} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.298039 0 0 0 0 0.454902 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_286_2717"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_286_2717"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_d_286_2717"
                x={22.8022}
                y={38.6572}
                width={24.9429}
                height={15.0449}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={2} />
                <feGaussianBlur stdDeviation={2} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.298039 0 0 0 0 0.454902 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_286_2717"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_286_2717"
                    result="shape"
                />
            </filter>
            <filter
                id="filter2_f_286_2717"
                x={55.875}
                y={24.333}
                width={14.7603}
                height={20.6279}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation={0.5}
                    result="effect1_foregroundBlur_286_2717"
                />
            </filter>
            <filter
                id="filter3_f_286_2717"
                x={-0.635254}
                y={24.333}
                width={14.7603}
                height={20.6279}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation={0.5}
                    result="effect1_foregroundBlur_286_2717"
                />
            </filter>
            <linearGradient
                id="paint0_linear_286_2717"
                x1={23.5155}
                y1={17.5101}
                x2={43.4434}
                y2={48.6027}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#D4B2FF" />
                <stop offset={1} stopColor="#70A9FF" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_286_2717"
                x1={15.3564}
                y1={11.9854}
                x2={54.8154}
                y2={12.3252}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#70A9FF" />
                <stop offset={1} stopColor="#4C74FF" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_286_2717"
                x1={63.2552}
                y1={25.333}
                x2={63.2552}
                y2={43.9605}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FF62C0" />
                <stop offset={0.505208} stopColor="#BE35FF" />
                <stop offset={1} stopColor="#7926FF" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_286_2717"
                x1={55.9637}
                y1={23.4707}
                x2={55.9637}
                y2={45.8236}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E4F9FF" stopOpacity={0.1} />
                <stop offset={1} stopColor="#E4F9FF" stopOpacity={0.8} />
            </linearGradient>
            <linearGradient
                id="paint4_linear_286_2717"
                x1={6.74496}
                y1={25.333}
                x2={6.74496}
                y2={43.9605}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FF62C0" />
                <stop offset={0.505208} stopColor="#BE35FF" />
                <stop offset={1} stopColor="#7926FF" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_286_2717"
                x1={14.0363}
                y1={23.4707}
                x2={14.0363}
                y2={45.8236}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E4F9FF" stopOpacity={0.1} />
                <stop offset={1} stopColor="#E4F9FF" stopOpacity={0.8} />
            </linearGradient>
            <linearGradient
                id="paint6_linear_286_2717"
                x1={22.6229}
                y1={5.38583}
                x2={44.8913}
                y2={5.38583}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#70A9FF" />
                <stop offset={1} stopColor="#4C74FF" />
            </linearGradient>
            <clipPath id="clip0_286_2717">
                <rect width={70} height={57} fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export default SVGComponent;
