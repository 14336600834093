import {useNavigate} from "react-router-dom";

export default function Test() {
    const navigate = useNavigate();
    const handleChange = (event) => {
        const {files} = event.target
        console.log('files', files)

        navigate("/resultPreview",{state: {files}})
    }

    return <div style={{position: "fixed", zIndex: "10000"}}>
        <input type="file" accept={"image/png"} onChange={handleChange}/>
    </div>
}
