import TheCardImage from "./TheCardImage";
import TheCardLink from "./TheCardLink";
import CoinIcon from "../SvgComponents/Coin";
import {useTranslation} from "react-i18next";



function BaseCard({cardData}) {
    const {name, id, credit_cost} = cardData
    const {t} = useTranslation()
    return <div className={"base-card"}>
        <TheCardImage imageName={name}/>
        <div className={"base-card__info"}>
            <CoinIcon className={"icon"} />
            <span className={"content"}>
                <span>{new Intl.NumberFormat("en").format(credit_cost)}</span>
                {t("coin_needed")}
            </span>
        </div>
        <div className={"base-card__actions"}>
            <TheCardLink linkTitle={name} action={name} id={id} servicePrice={credit_cost}/>
        </div>
    </div>
}

export default BaseCard